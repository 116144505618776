import React, { useEffect, useRef, memo } from 'react';
import classnames from 'classnames';
import track from 'react-tracking';
import { noop } from '@xxxlgroup/hydra-utils/common';

import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';

import Message from 'components/Message';

import styles from 'components/Header/components/Hamburger/Hamburger.scss';

export const HAMBURGER_BUTTON_PURPOSE = 'header.button.hamburger';

interface HamburgerProps {
  /** callback to close the menu */
  closeMenu: () => void;
  /** dictates if BurgerMenu opened/closed */
  isBurgerActive: boolean;
  /** bubbles up event from Header */
  onActivate?: () => void;
  /** callback to open the menu */
  openMenu: () => void;
}

const Hamburger = (props: HamburgerProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { closeMenu, isBurgerActive, onActivate = noop, openMenu } = props;
  const tracking = useTracking(props, 'Hamburger');

  const hamburgerClick = (event: React.PointerEvent<HTMLButtonElement>) => {
    tracking(event);
    onActivate();

    isBurgerActive ? closeMenu() : openMenu();
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key !== 'Escape') {
        return;
      }
      closeMenu();
      buttonRef?.current?.focus();
    };

    if (isBurgerActive) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeMenu, isBurgerActive]);

  const handleHover = (
    event: React.FocusEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    tracking(event);
  };

  return (
    <Message code="wxs.header.hamburger.button">
      {(message) => (
        <button
          ref={buttonRef}
          data-purpose={HAMBURGER_BUTTON_PURPOSE}
          className={classnames(styles.hamburger, isBurgerActive ? styles.active : styles.inactive)}
          type="button"
          data-toggle="collapse"
          aria-expanded={isBurgerActive ? 'true' : 'false'}
          aria-label={typeof message === 'string' ? message : ''}
          onClick={hamburgerClick}
          onMouseEnter={handleHover}
          onFocus={handleHover}
          tabIndex={0}
        >
          <span
            role="presentation"
            className={classnames(styles.wrapper, { [styles.active]: isBurgerActive })}
          >
            {/* The following spans are for visual purposes only to create the icon animation */}
            <span className={classnames(styles.iconbar, styles.firstIconbar)} />
            <span className={classnames(styles.iconbar, styles.secondIconbar)} />
            <span className={classnames(styles.iconbar, styles.thirdIconbar)} />
            <span className={classnames(styles.iconbar, styles.fourthIconbar)} />
          </span>
        </button>
      )}
    </Message>
  );
};

export default memo(track(tagComponent('Hamburger'))(Hamburger));
