import React, { FC } from 'react';
import classNames from 'classnames';
import { Content, Image } from '@xxxlgroup/hydra-ui-components';
import { getProductUrl } from 'utils/url';
import { highlightSearch } from 'components/Header/components/Suggest/Suggest.utils';
import Link from 'components/WebshopLink';
import ProductNumber from 'components/ProductNumber';

import suggestStyles from 'components/Header/components/Suggest/Suggest.scss';
import styles from 'components/Header/components/Suggest/components/ProductItems/ProductItems.scss';

interface Items {
  /** Product code ID from FredHopper */
  fhId: string;
  /** Product/article number for the product item */
  formattedId: string;
  /** Randomly generated unique string */
  id: string;
  /** URL for the product item's image */
  image: string;
  /** Label or name of the product item */
  label: string;
  /** Product item's cost */
  price: string;
  /** Suggested product item's URL */
  url?: string;
}

interface ProductItemsProps {
  /** Handle click callback */
  handleClick?: (event: React.MouseEvent) => void;
  /** Items to display */
  items: Items[];
  /** Search term to highlight  */
  searchTerm?: string;
}

const getSuggestedProductUrl = ({ fhId, url }: Items) =>
  url ? new URL(url).pathname : getProductUrl(fhId);

const ProductItems: FC<ProductItemsProps> = ({ handleClick, items, searchTerm = '' }) => (
  <>
    {items.map((item: Items) => (
      <li key={item.id} className={suggestStyles.item}>
        <Link
          data-product-id={item.fhId || item.id}
          data-purpose="header.suggest.product.link"
          href={getSuggestedProductUrl(item)}
          onClick={handleClick}
          className={styles.product}
        >
          <Image source={item.image} width={50} height={50} />
          <div className={styles.productData}>
            <div className={classNames(styles.productName)}>
              {highlightSearch(item.label, searchTerm)}
            </div>
            <ProductNumber content={item.formattedId} className={styles.productNumber} />
            <Content content={item.price} className={classNames(styles.productPrice)} />
          </div>
        </Link>
      </li>
    ))}
  </>
);

export default ProductItems;
