export const LEVELS_WITH_ACTIVATED_FIRST_SUB_ITEM = [0];
export const LEVELS_WITH_HOVER = [0, 1];
export const LEVELS_WITH_ICONS_ALLOWED = [0, 1, 2];
export const LEVELS_WITH_PRELOAD_ON_HOVER = [0];
export const LEVELS_WITH_SHOW_ALL_BUTTON = [3];
export const MAX_RETRY_COUNT = 3;
export const MAX_NESTED_LEVELS = 3;
export const MAX_RENDERED_SUB_ITEMS = 5;
export const MAX_RENDERED_SUB_ITEMS_IF_SPACE_AVAILABLE = 18;
export const RETRY_TIMEOUT = 500;
export const SUBMENU_HIT_ALLOWED_MOUSE_MOVE_ANGLE = 60;
export const SUBMENU_HIT_MOUSE_MOVE_DETECTION_TIMEOUT = 300;
