import React from 'react';
import PropTypes from 'prop-types';
import { noop } from '@xxxlgroup/hydra-utils/common';

import Link from 'components/WebshopLink';
import { highlightSearch } from 'components/Header/components/Suggest/Suggest.utils';
import suggestStyles from 'components/Header/components/Suggest/Suggest.scss';

const getSuggestedCategoryUrl = (suggestedCategory) =>
  suggestedCategory.url
    ? new URL(suggestedCategory.url).pathname
    : suggestedCategory.label?.toLowerCase().replace(/[^a-z0-9]+/g, '-');

const CategoryItems = ({ handleClick, items, searchTerm }) =>
  items
    ? items.map((item) => (
        <li key={item.id} className={suggestStyles.item}>
          <Link
            data-purpose="header.suggest.category.link"
            href={getSuggestedCategoryUrl(item)}
            onClick={handleClick}
            className={suggestStyles.link}
          >
            <span>{highlightSearch(item.label, searchTerm)}</span>
          </Link>
        </li>
      ))
    : null;

CategoryItems.propTypes = {
  /** Handle click callback */
  handleClick: PropTypes.func,
  /** Items to display */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      fhId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  /** Search term to highlight  */
  searchTerm: PropTypes.string,
};

CategoryItems.defaultProps = {
  handleClick: noop,
  items: null,
  searchTerm: null,
};

export default CategoryItems;
