import React, { useCallback, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ReactTypes } from 'types';
import track from 'react-tracking';
import { useQuery } from '@apollo/client';
import { FeedbackCard } from '@xxxlgroup/hydra-ui-components';
import { isObjectEmpty } from '@xxxlgroup/hydra-utils/common';
import { debouncing } from 'utils/function';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useSearchResultsTracking from 'components/Listing/hooks/useSearchResultsTracking';
import { FHSUGGEST_QUERY } from 'components/Header/components/Suggest/SuggestFredhopper.query';
import { SUGGESTSEARCH_QUERY } from 'components/Header/components/Suggest/SuggestSearch.query';
import useMessage from 'components/Message/useMessage';
import SuggestList from 'components/Header/components/Suggest/components/SuggestList';
import KeywordItems from 'components/Header/components/Suggest/components/KeywordItems';
import CategoryItems from 'components/Header/components/Suggest/components/CategoryItems';
import ProductItems from 'components/Header/components/Suggest/components/ProductItems';
import { useSearchBar } from 'components/Header/components/SearchBar/SearchBar.variables';
import { useLanguageSelector } from 'components/MetaNavigation/components/LanguageSelector/useLanguageSelector';
import styles from 'components/Header/components/Suggest/Suggest.scss';

const MAXIMUM_ITEMS_PER_GROUP = 3;
const MIN_SEARCHTERM_LENGTH = 3;

const SUGGEST_SEARCH_METHOD_MAP = {
  'header.suggest.keyword.link': 'suggested_keyword',
  'header.suggest.category.link': 'suggested_category',
  'header.suggest.product.link': 'suggested_product',
};

const getReducedData = (array) => array.slice(0, MAXIMUM_ITEMS_PER_GROUP);

const Suggest = (props) => {
  const { className, inputWrapperRef, onClickLink, setIsSuggestEmpty, term, updateDebounceTerm } =
    props;
  const tracking = useTracking(props, 'Suggest');
  const [isVoiceSearchShown, newSuggestSearchEnabled, isLanguageSelectorEnabled] = useFeatureFlags([
    'poseidon.searchBar.voiceSearch.enabled',
    'poseidon.searchBar.suggestSearch.new.enabled',
    'poseidon.multi.language.support.enabled',
  ]);
  const [keywordsTitle, categoriesTitle, productsTitle, searchInfo] = useMessage([
    'wxs.search.results.keywordTitle',
    'wxs.search.results.categoriesTitle',
    'wxs.search.results.productsTitle',
    'wxs.header.searchBar.placeholder',
  ]);
  const { searchResultTracking, searchTracking } = useSearchResultsTracking(tracking);

  const query = newSuggestSearchEnabled ? SUGGESTSEARCH_QUERY : FHSUGGEST_QUERY;
  const shouldSuggestFetch = decodeURIComponent(term)?.trim().length >= MIN_SEARCHTERM_LENGTH;

  const { currentLanguage, defaultLanguage } = useLanguageSelector();
  const shouldShowSearchInfo = isLanguageSelectorEnabled && currentLanguage !== defaultLanguage;

  const { data, previousData, variables, error } = useQuery(query, {
    variables: {
      term,
    },
    skip: !shouldSuggestFetch,
  });

  const {
    categories = [],
    keywords = [],
    products = [],
  } = newSuggestSearchEnabled
    ? data?.getSuggestSearch ?? previousData?.getSuggestSearch ?? {}
    : data?.getFredhopperSuggest ?? previousData?.getFredhopperSuggest ?? {};

  const { updateSuggestVisibility, updateSearchTerm } = useSearchBar();

  const isSuggestEmpty =
    isObjectEmpty(products) && isObjectEmpty(categories) && isObjectEmpty(keywords);

  useEffect(() => {
    setIsSuggestEmpty(isSuggestEmpty);
  }, [isSuggestEmpty, setIsSuggestEmpty]);

  const searchTerm = decodeURIComponent(variables.term);

  const handleClick = useCallback(
    (event) => {
      const link = event.currentTarget.closest('a');
      searchTracking.current.method =
        SUGGEST_SEARCH_METHOD_MAP[String(link?.getAttribute('data-purpose'))];

      if (searchTracking.current.method === 'suggested_product') {
        searchTracking.current.term = link?.getAttribute('data-product-id') ?? '';
        searchTracking.current.productCodes = [searchTracking.current.term];
        searchTracking.current.totalResults = 1;

        searchResultTracking();
      } else {
        searchTracking.current.term = String(link?.textContent);
      }
      updateSuggestVisibility(false);
      onClickLink();
    },
    [onClickLink, searchResultTracking, searchTracking, updateSuggestVisibility],
  );

  const typeAhead = useCallback(
    (suggestedTerm) => (event) => {
      tracking(event);
      inputWrapperRef.current?.firstChild.focus();

      const typeAheadTerm = `${suggestedTerm} `;
      updateSearchTerm(typeAheadTerm);
      updateDebounceTerm(typeAheadTerm);
    },
    [tracking, inputWrapperRef, updateSearchTerm, updateDebounceTerm],
  );

  const debouncedLeave = useMemo(
    () =>
      debouncing(() => {
        updateSuggestVisibility(false);
        inputWrapperRef.current?.firstChild.blur();
      }),
    [updateSuggestVisibility, inputWrapperRef],
  );

  const handleHover = useCallback(
    (event) => {
      tracking(event);
    },
    [tracking],
  );

  const handleMouseEnter = useCallback(() => {
    debouncedLeave.cancel();
  }, [debouncedLeave]);

  if (error) {
    return null;
  }

  return isSuggestEmpty ? (
    <>
      <div
        className={classnames(styles.extraBorder, {
          [styles.suggestWithVoiceSearch]: isVoiceSearchShown,
        })}
      />
      {shouldShowSearchInfo && (
        <FeedbackCard
          className={classnames(styles.suggest, styles.searchInfo)}
          timerDuration="quick"
          fadeIn
        >
          {searchInfo}
        </FeedbackCard>
      )}
    </>
  ) : (
    <div
      className={classnames(className, styles.suggest, {
        [styles.suggestWithVoiceSearch]: isVoiceSearchShown,
      })}
      data-purpose="header.suggest.container"
      onMouseEnter={handleMouseEnter}
    >
      <>
        {shouldShowSearchInfo && (
          <FeedbackCard className={styles.searchInfo} timerDuration="quick" fadeIn>
            {searchInfo}
          </FeedbackCard>
        )}
        <SuggestList heading={keywordsTitle} data-purpose="header.suggest.keywords">
          {keywords && (
            <KeywordItems
              handleClick={handleClick}
              handleHover={handleHover}
              items={getReducedData(keywords)}
              searchTerm={searchTerm}
              typeAhead={typeAhead}
            />
          )}
        </SuggestList>
        <SuggestList heading={categoriesTitle} data-purpose="header.suggest.categories">
          {categories && (
            <CategoryItems
              handleClick={handleClick}
              items={getReducedData(categories)}
              searchTerm={searchTerm}
            />
          )}
        </SuggestList>
        <SuggestList heading={productsTitle} data-purpose="header.suggest.products">
          {products && (
            <ProductItems
              handleClick={handleClick}
              items={getReducedData(products)}
              searchTerm={searchTerm}
            />
          )}
        </SuggestList>
      </>
    </div>
  );
};

Suggest.propTypes = {
  /** Additional class name */
  className: PropTypes.string,
  /** Input field reference */
  inputWrapperRef: ReactTypes.ref.isRequired,
  /** callback for clicking on the link */
  onClickLink: PropTypes.func.isRequired,
  /** callback that sets if the suggest dropdown is empty or not */
  setIsSuggestEmpty: PropTypes.func.isRequired,
  /** debounce term state from searchBar */
  term: PropTypes.string.isRequired,
  /** update debounce term function from searchBar */
  updateDebounceTerm: PropTypes.func.isRequired,
};

Suggest.defaultProps = {
  className: '',
};

export default track(tagComponent('Suggest'))(Suggest);
