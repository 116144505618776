import { Target } from '@xxxlgroup/hydra-ui-components/dist/common/molecules/Card/Card.types';
import { CardOverlayType } from '@xxxlgroup/hydra-ui-components/dist/common/molecules/Card/components/CardOverlay/CardOverlay.types';
import React from 'react';
import { RouteComponentProps } from 'react-router';

export interface BackButtonProps {
  /** bubbles up the "back" event from NavigationList */
  onClick: (event: React.PointerEvent, level: number) => void;
  /** how deeply the component is nested within the navigation */
  level: number;
}

export interface CloseForTouchProps {
  /** callback function to close navigation */
  onClick: (event: InteractiveUIEventTypes) => void;
}

export type InteractiveUIEventTypes =
  | React.MouseEvent<HTMLLIElement>
  | React.TouchEvent<HTMLAnchorElement>
  | React.TouchEvent<HTMLDivElement>
  | React.PointerEvent<HTMLElement>
  | TouchEvent
  | KeyboardEvent;

export interface MyAccountTeaserProps {
  /** callback function to close hamburger menu */
  onCloseHamburger?: (event: React.PointerEvent) => void;
}

export enum NavigationCardSize {
  FULL = 'FULL',
  HALF = 'HALF',
  THIRD = 'THIRD',
  SPECIAL = 'SPECIAL',
}

export interface NavigationCardProps {
  /** includes all necessary card data */
  data: {
    className?: string;
    cmsComponentName?: string;
    image: Record<string, unknown>;
    link?: string;
    target?: Target;
    title?: string;
    variant?: CardOverlayType;
    level?: number;
    id?: string;
    isPromotion?: boolean;
  };
  /** bubbles up event */
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  /** tells the component how big it is to be able to calculate the proper image sizes */
  size?: NavigationCardSize;
}

export interface NavigationProps extends RouteComponentProps {
  /** @ignore */
  className?: string;
  /** Whether the navigation should be displayed (mobile only). */
  isBurgerActive?: boolean;
  /** Boolean: if navbar has focus or not */
  isNavFocused?: boolean;
  /** Boolean: if hamburgerNavigation or not */
  isHamburgerNavigation?: boolean;
  /** state setter for navigation hover/focus */
  setIsNavFocused: React.Dispatch<React.SetStateAction<boolean>>;
  /** callback function to close hamburger menu */
  onCloseHamburger?: (event?: React.MouseEvent) => void;
  /** bubbles up event from NavigationButton */
  onMouseEnter?: (event: InteractiveUIEventTypes, itemId?: string) => void;
  /** bubbles up event from NavigationButton */
  onMouseLeave?: (event: InteractiveUIEventTypes) => void;
}

export type LayoutTypes = 'standard' | 'teaser';

export type NaviItem = {
  code?: string;
  className?: string;
  /** @deprecated Field is not in use. */
  cmsComponentName?: string;
  hasSubItems?: boolean;
  id?: string;
  image: Record<string, unknown>;
  layoutType: LayoutTypes;
  link?: string;
  name?: string;
  target?: Target;
  title?: string;
  type?: string;
  variant?: CardOverlayType;
  svg?: string;
  highlighted: boolean;
};

export interface NavigationListProps {
  /** if navigation(Item) only has one category */
  hasOnlyOneSubItem?: boolean;
  /** id of the NavigationItem */
  id?: string;
  /** Boolean: if hamburgerNavigation or not */
  isHamburgerNavigation?: boolean;
  /** Boolean: if navigation is scrollable */
  isScrollable?: boolean;
  /** Boolean: if navigation has focus */
  isNavFocused?: boolean;
  /** how deeply the component is nested within the navigation */
  level?: number;
  /** url the button links to */
  link?: string;
  /** bubbles up event from NavigationButton */
  onClickItem?: (event: React.MouseEvent, hasSubItems: boolean, level: number) => void;
  /** bubbles up the "back" event from NavigationList */
  onClickLevelBack?: (event: React.PointerEvent, currentLevel: number) => void;
  /** callback function to close hamburger menu */
  onCloseHamburger?: (event: React.PointerEvent) => void;
  /** bubbles up event from NavigationButton */
  onMouseEnter?: (event: InteractiveUIEventTypes, itemId?: string) => void;
  /** callback on mouse leave on item level 0 */
  onMouseLeave?: (event: InteractiveUIEventTypes) => void;
  /** closes or opens the flyout menu /  bubbles up event to remove hover status */
  onRemoveHoverStatusImmediately?: (event: InteractiveUIEventTypes) => void;
  /** only shows a certain number of navItems in desktop */
  maxItemsShown?: number;
  /** name of the component */
  name?: string;
  /** parent category for this list */
  parentItem?: {
    code?: string;
    id?: string;
    layoutType?: LayoutTypes;
    link?: string;
    name?: string;
    svg?: string;
  };
  /** if true render all sub items */
  renderAllSubLevels?: boolean; // eslint-disable-line react/boolean-prop-naming
  /** state setter for navigation hover/focus */
  setIsNavFocused?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface NavigationItemProps {
  /** @ignore */
  className?: string;
  /** uuid of the NavigationItem */
  code?: string;
  /** if navigation(Item) only has one category */
  hasOnlyOneSubItem?: boolean;
  /** indicates availability of subItems */
  hasSubItems?: boolean;
  /** id of the NavigationItem */
  id?: string;
  /** Boolean: if hamburgerNavigation or not */
  isHamburgerNavigation?: boolean;
  /** Boolean: if navigation has focus */
  isNavFocused?: boolean;
  /** if this link is go to all products in category */
  isOverview?: boolean;
  /** bubbles up event from NavigationButton */
  onMouseEnter?: (event: InteractiveUIEventTypes) => void;
  /** layout of navigation in level 2 */
  layoutType?: LayoutTypes;
  /** how deeply the component is nested within the navigation */
  level?: number;
  /** url the button links to */
  link?: string;
  /** name of the component */
  name?: string;
  /** bubbles up event from NavigationButton */
  onClickItem?: (event: React.MouseEvent, hasSubItems: boolean, level: number) => void;
  /** bubbles up the "back" event from NavigationList */
  onClickLevelBack?: (event: React.PointerEvent, currentLevel: number) => void;
  /** callback leave */
  onMouseLeave?: (event: InteractiveUIEventTypes) => void;
  /** closes or opens the flyout menu /  bubbles up event to remove hover status */
  onRemoveHoverStatusImmediately?: (event: InteractiveUIEventTypes) => void;
  /** if true render all sub items */
  renderAllSubLevels?: boolean; // eslint-disable-line react/boolean-prop-naming
  /** state setter for navigation hover/focus */
  setIsNavFocused?: React.Dispatch<React.SetStateAction<boolean>>;
  /** icon displayed next to the button */
  svg?: string;
  /** highlight link with primary-color if is set */
  isHighlightLink?: boolean;
}

export interface NavigationItemLinkProps {
  /** add className */
  className?: string;
  /** if this Item has SubItems */
  hasSubItems?: boolean;
  handleMouseLeave?: (event: InteractiveUIEventTypes) => void;
  /** Boolean: if navigation has focus */
  isNavFocused?: boolean;
  /** if this link is go to all products in category */
  isOverview?: boolean;
  /** how deeply the component is nested within the navigation */
  level?: number;
  /** url the button links to */
  link?: string;
  /** name of the NavigationItem */
  name?: string;
  /** callback click */
  onClick?: (event: React.PointerEvent<HTMLAnchorElement>) => void;
  /** callback focus */
  onFocus?: (event: React.FocusEvent) => void;
  /** callback focus */
  handleOpenNavWithKeyboard?: (event: any) => void;
  /** bubbles up event from NavigationItem */
  onTouchEnd?: (event: InteractiveUIEventTypes) => void;
  /** icon displayed next to the button */
  svg?: string;
  /** opens _self or _blank */
  target?: string;
}
