import styles from 'components/Header/components/Navigation/Navigation.scss';

export const getFirstSubMenuItemElement = (event: KeyboardEvent) => {
  const { nextElementSibling } = event.target as HTMLAnchorElement;

  // notice children[2]: children[0] is the mobile "back" button and children[1] is the mobile overview button.
  return nextElementSibling?.children[2];
};

const enum LevelOrientation {
  horizontal,
  vertical,
}

export const getLevelOrientation = (currentLevel: number, isHamburgerNavigation: boolean) => {
  if (!isHamburgerNavigation && (currentLevel === 0 || currentLevel === 2)) {
    return LevelOrientation.horizontal;
  }

  return LevelOrientation.vertical;
};

export const getNextMenuItemElement = (event: KeyboardEvent) =>
  ((event.target as HTMLAnchorElement).parentElement as HTMLLIElement).nextElementSibling;

export const getParentMenuItemElement = (event: KeyboardEvent, currentLevel: number) => {
  if (currentLevel < 1) {
    return null;
  }

  return (event.target as HTMLAnchorElement).closest(`li[data-level="${currentLevel - 1}"]`);
};

export const getPreviousMenuItemElement = (
  event: KeyboardEvent,
  isHamburgerNavigation: boolean,
) => {
  const { previousElementSibling } = (event.target as HTMLAnchorElement)
    .parentElement as HTMLLIElement;

  if (!isHamburgerNavigation && previousElementSibling) {
    if (
      previousElementSibling.hasAttribute('data-backbutton') ||
      previousElementSibling.hasAttribute('data-overview')
    ) {
      return null;
    }
  }

  return previousElementSibling;
};

export const handleKeyboard = {
  [LevelOrientation.vertical]: (
    event: KeyboardEvent,
    key: string,
    isHamburgerNavigation: boolean,
    parentMenuItemElement: HTMLLIElement,
    previousMenuItemElement: HTMLLIElement,
    firstSubMenuItemElement: HTMLLIElement,
    nextMenuItemElement: HTMLLIElement,
  ) => {
    if (key === 'ArrowUp') {
      if (previousMenuItemElement) {
        (previousMenuItemElement.firstChild as HTMLAnchorElement).focus();
        event.preventDefault();
      } else if (parentMenuItemElement) {
        (parentMenuItemElement.firstChild as HTMLAnchorElement).focus();
        event.preventDefault();
      }
    } else if (key === 'ArrowDown' && nextMenuItemElement) {
      (nextMenuItemElement.firstChild as HTMLAnchorElement).focus();
      event.preventDefault();
    } else if (!isHamburgerNavigation && key === 'ArrowRight' && firstSubMenuItemElement) {
      (firstSubMenuItemElement.firstChild as HTMLAnchorElement).focus();
    }
  },
  [LevelOrientation.horizontal]: (
    event: KeyboardEvent,
    key: string,
    isHamburgerNavigation: boolean,
    parentMenuItemElement: HTMLLIElement,
    previousMenuItemElement: HTMLLIElement,
    firstSubMenuItemElement: HTMLLIElement,
    nextMenuItemElement: HTMLLIElement,
  ) => {
    if (key === 'Enter' && nextMenuItemElement) {
      if ((event.target as HTMLButtonElement)?.firstChild?.nodeName === 'I') {
        requestIdleCallback(() => {
          setTimeout(() => {
            (firstSubMenuItemElement.firstChild as HTMLAnchorElement).focus();
          }, 300);
        });
      }
    }

    if (key === 'ArrowRight' && nextMenuItemElement) {
      (nextMenuItemElement.firstChild as HTMLAnchorElement).focus();
    } else if (key === 'ArrowDown' && firstSubMenuItemElement) {
      (firstSubMenuItemElement.firstChild as HTMLAnchorElement).focus();
      event.preventDefault();
    } else if (key === 'ArrowLeft') {
      if (previousMenuItemElement) {
        (previousMenuItemElement.firstChild as HTMLAnchorElement).focus();
      } else if (parentMenuItemElement) {
        (parentMenuItemElement.firstChild as HTMLAnchorElement).focus();
      }
    }
  },
};

export const resetNavigationClasses = (ref: HTMLElement) => {
  const currNavRefClassList = ref.classList;
  currNavRefClassList.remove(styles[`current-1`]);
  currNavRefClassList.remove(styles[`current-2`]);
  currNavRefClassList.remove(styles[`current-3`]);
  currNavRefClassList.add(styles[`current-0`]);
  ref.querySelectorAll('.activeMainNavigationItem').forEach((node: Element) => {
    node.classList.remove('activeMainNavigationItem');
  });
  ref.querySelectorAll('[data-hovered]').forEach((node: Element) => {
    node?.removeAttribute('data-hovered');
  });
  ref.querySelectorAll('a[tabindex], button[tabindex]').forEach((node: Element) => {
    node?.setAttribute('tabindex', '0');
  });
};
