import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useLocation, useRouteMatch } from 'react-router';
import * as icons from '@xxxlgroup/hydra-icons';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { Content, FeedbackCard, Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import useMediaQuery from 'components/MediaQuery/useMediaQuery';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import usePrevious from 'hooks/usePrevious';
import useRoutes from 'hooks/useRoutes';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import { getCurrentCart, useCartsQuery } from 'utils/cart';
import { disableScrolling, enableScrolling } from 'utils/common';
import ErrorBoundary from 'components/ErrorBoundary';
import FavouritesFlyout from 'modules/favourites/components/FavouritesFlyout/FavouritesFlyout';
import ActionIcon from 'components/Header/components/ActionIcon';
import Hamburger from 'components/Header/components/Hamburger';
import ReservationsMini from 'components/Entries/EntryLists/ReservationsMini';
import CartMini from 'components/Entries/EntryLists/CartMini';
import SearchBar from 'components/Header/components/SearchBar';
import Message from 'components/Message';
import useMessage from 'components/Message/useMessage';
import Link from 'components/WebshopLink';
import Navigation from 'components/Header/components/Navigation';
import LogoButton from 'components/LogoButton';
import HeaderLoginActionIcon from 'modules/customer-authentication/components/HeaderLoginActionIcon';
import HeaderLogoutActionIcon from 'modules/customer-authentication/components/HeaderLogoutActionIcon';
import { getFavouritesProducts } from 'modules/favourites/utils/favourites';
import { FavouritesContext } from 'modules/favourites/pages/Favourites/Favourites.state';

import Indicator from 'components/Header/components/Indicator';
import useIndicator from 'components/Header/components/Indicator/Indicator.hook';
import MetaNavigation from 'components/MetaNavigation';
import useIsModeHeadless from 'hooks/useIsModeHeadless';
import useMergeCart from 'pages/Cart/hooks/useMergeCart';
import { getTotalProductsAmount } from 'utils/cart/misc';
import SkipNavigation from 'components/Header/components/SkipNavigation';
import useIsMobileApp from 'hooks/useIsMobileApp';
import useIsMountedOnCSR from 'hooks/useIsMountedOnCsr';

import styles from 'components/Header/Header.scss';

const Header = (props) => {
  const { hasIndicator } = props;

  const promotionEngineEnabled = useFeatureFlagsEnabled('poseidon.promotion.engine');
  const { cart: cartIcon, like, location: locationGlyph, reserve } = icons;

  const { cart: cartLink, checkout, favouritesPage, orders, reservationPage } = useRoutes();
  const matchReservationPage = useRouteMatch({
    path: [reservationPage.root, checkout.root],
  });
  const matchConfirmationPage = useRouteMatch({ path: [orders.confirmation] });

  // Mini-cart, reservations and favourites do not make sense to render on the server because we do not know localstorage
  // state of the browser. This hook ensures that they get re-rendered on CSR hydration.
  const [isBurgerActive, setIsBurgerActive] = useState(false);
  const isMountedOnCSR = useIsMountedOnCSR();
  const { isAnonymous } = useAuth();
  const skipGetFavourites = !useFeatureFlagsEnabled('poseidon.favourites');
  const isMetaNavigationEnabled = useFeatureFlagsEnabled('poseidon.nav.metaNavigation.enabled');
  const isStoreFinderEnabled = useFeatureFlagsEnabled('poseidon.storefinder.link');
  const skipGetCarts =
    !useFeatureFlagsEnabled(['poseidon.checkout', 'checkout']) || matchConfirmationPage;
  const skipGetReservations = !useFeatureFlagsEnabled(['poseidon.reservation', 'reservation']);
  const newCartMergeEnable = useFeatureFlagsEnabled('login.cartMerge.disabled');
  const [storeFinderLinkText, storeFinderLink] = useMessage(
    ['storefinder.link', 'storefinder.link.url', 'a11y.main.navigation.steering.arrows'],
    undefined,
    true,
  );

  const isMobileLayout = useMediaQuery({ smallerThan: 'lg' });

  const location = useLocation();
  const isModeHeadless = useIsModeHeadless();
  const isMobileApp = useIsMobileApp();

  const {
    hideEvent: hideIndicator,
    leftOffset: indicatorLeftOffset,
    showEvent: showIndicator,
    width: indicatorWidth,
  } = useIndicator();

  const [isNavFocused, setIsNavFocused] = useState(false);
  const [showPromoFeedback, setShowPromoFeedback] = useState(true);
  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const [hideFlyout, setHideFlyout] = useState(false);
  const prevHideFlyout = usePrevious(hideFlyout);

  if (prevHideFlyout && hideFlyout) {
    setHideFlyout(false);
  }

  useEffect(() => {
    setHideFlyout(true);
  }, [location]);

  const { favouritesState } = useContext(FavouritesContext);
  const { favourites } = favouritesState;
  const products = getFavouritesProducts(favourites);

  const {
    data: orderCarts = {},
    loading: cartsLoading,
    refetch: refetchCart,
  } = useCartsQuery({
    fetchPolicy: 'network-only',
    skip: skipGetCarts,
  });

  const isReduced = useMemo(() => {
    if ([`/${reservationPage.name}`].includes(location.pathname)) {
      return false;
    }

    return !!matchReservationPage;
  }, [location.pathname, matchReservationPage, reservationPage.name]);

  const cart = useMemo(() => getCurrentCart(orderCarts), [orderCarts]);

  const promotionRemoved = useMemo(() => {
    if (isReduced && promotionEngineEnabled) {
      return [];
    }

    return (
      cart.messages?.filter(
        (message) =>
          message.message.includes('promotion.feedback') ||
          message.message.includes('promotion.coupon.invalid.removed'),
      ) || []
    );
  }, [cart.messages, isReduced, promotionEngineEnabled]);

  const {
    data: reservationCarts = {},
    loading: reservationLoading,
    refetch: refetchReservation,
  } = useCartsQuery({
    skip: skipGetReservations,
    variables: { type: 'RESERVATION' },
  });

  useEffect(() => {
    refetchReservation();
  }, [isReduced, refetchReservation]);

  const favouritesCount = useMemo(() => products?.length || 0, [products]);

  const reservationProductsCount = useMemo(
    () => getTotalProductsAmount(reservationCarts?.getCarts?.carts?.[0]?.entries),
    [reservationCarts?.getCarts?.carts],
  );
  const orderedProductsCount = useMemo(
    () => getTotalProductsAmount(orderCarts?.getCarts?.carts?.[0]?.entries),
    [orderCarts?.getCarts?.carts],
  );

  const openOverlay = useCallback(() => {
    setOverlayOpen(true);
  }, []);

  const closeOverlay = useCallback(() => {
    setOverlayOpen(false);
  }, []);

  const handleKeyDown = useCallback(
    (event) => event.key === 'Enter' && closeOverlay(),
    [closeOverlay],
  );
  const promotionEngine = promotionEngineEnabled && !isArrayEmpty(promotionRemoved);

  const updateIsBurgerActive = useCallback((active) => {
    if (active) {
      disableScrolling('mobile');
    } else {
      enableScrolling('mobile');
    }

    setIsBurgerActive(active);
  }, []);

  const closeMobileMenu = useCallback(() => {
    updateIsBurgerActive(false);
    closeOverlay();
  }, [updateIsBurgerActive, closeOverlay]);

  const openMobileMenu = useCallback(() => {
    updateIsBurgerActive(true);
  }, [updateIsBurgerActive]);

  useEffect(() => {
    if (isArrayEmpty(promotionRemoved)) {
      setShowPromoFeedback(true);
    }
  }, [promotionRemoved]);

  useEffect(() => {
    if (isBurgerActive && !isMobileLayout) {
      closeMobileMenu();
    }
  }, [closeMobileMenu, isBurgerActive, isMobileLayout]);

  const mergeCart = useMergeCart({
    refetchCart,
    isAnonymous,
    refetchReservation,
    reservationId: reservationCarts?.getCarts?.carts?.[0]?.code,
    cartId: orderCarts?.getCarts?.carts?.[0]?.code,
  });

  useEffect(() => {
    if (newCartMergeEnable) {
      mergeCart();
    }
  }, [mergeCart, newCartMergeEnable]);

  const toggleInertAttribute = useCallback(() => {
    const header = document.querySelector('header');
    const footer = document.querySelector('footer');
    const meta = header?.querySelector(':scope > [data-purpose="meta.navigation"]');
    const siblings = header?.parentElement?.querySelectorAll(':scope > *:not(header)') || [];

    const elements = [meta, footer, ...siblings].filter(Boolean);

    elements.forEach((element) => {
      if (isBurgerActive) {
        element.setAttribute('inert', 'inert');
      } else {
        element.removeAttribute('inert');
      }
    });
  }, [isBurgerActive]);

  useEffect(() => {
    toggleInertAttribute();
  }, [isBurgerActive, toggleInertAttribute]);

  useEffect(() => {
    refetchCart();
  }, [isAnonymous, refetchCart]);

  useEffect(() => {
    refetchReservation();
  }, [isAnonymous, refetchReservation]);

  const renderNav = useMemo(
    () => (
      <ErrorBoundary>
        <Navigation
          fallback={<div />}
          isBurgerActive={isBurgerActive}
          isHamburgerNavigation={isMobileLayout}
          onCloseHamburger={closeMobileMenu}
          onMouseEnter={showIndicator}
          onMouseLeave={hideIndicator}
          setIsNavFocused={setIsNavFocused}
          isNavFocused={isNavFocused}
        />
      </ErrorBoundary>
    ),
    [closeMobileMenu, hideIndicator, isBurgerActive, isMobileLayout, isNavFocused, showIndicator],
  );

  const renderSearchBar = useMemo(
    () => (
      <SearchBar
        closeHamburger={closeMobileMenu}
        closeOverlay={closeOverlay}
        isBurgerActive={isBurgerActive}
        isMobileLayout={isMobileLayout}
        isOverlayOpen={isOverlayOpen}
        openOverlay={openOverlay}
        setHideFlyout={setHideFlyout}
      />
    ),
    [closeMobileMenu, closeOverlay, isBurgerActive, isMobileLayout, isOverlayOpen, openOverlay],
  );

  const renderLogoMessage = (
    <LogoButton
      dataPurpose="header.railLogo"
      linkClassName={classnames(styles.brand, {
        [styles.reducedHeaderLogo]: isReduced,
      })}
      onClick={closeMobileMenu}
      iconClassName={styles.logo}
    />
  );

  const renderStoreLocator = useCallback(
    () => (
      <div
        className={classnames(styles.storeLocator, {
          [styles.reducedHeaderLogo]: isReduced,
        })}
      >
        <Link
          as={<HydraLink glyphAfter={locationGlyph} theme="coal" />}
          data-purpose="header.link.storefinder"
          href={storeFinderLink}
        >
          {storeFinderLinkText}
        </Link>
      </div>
    ),
    [locationGlyph, isReduced, storeFinderLink, storeFinderLinkText],
  );

  const headerClass = classnames(styles.headerContainer, {
    [styles.reduced]: isReduced,
    [styles.searchBarSpacing]: !isReduced,
    [styles.withOverlay]: isOverlayOpen,
  });

  const renderFavouritesActionIcon = useMemo(
    () => (
      <Message code={['favourites.link.ariaLabel']}>
        {(favouritesAriaLabel) => (
          <ActionIcon
            ariaLabel={favouritesAriaLabel}
            badge={favouritesCount}
            dataPurpose="wxs.header.actions.favourites"
            glyph={like}
            isMobileLayout={isMobileLayout}
            link={favouritesPage.root}
            onBlur={hideIndicator}
            onClick={closeMobileMenu}
            onFocus={showIndicator}
            onMouseEnter={showIndicator}
            onMouseLeave={hideIndicator}
          >
            {!hideFlyout && isMountedOnCSR && <FavouritesFlyout />}
          </ActionIcon>
        )}
      </Message>
    ),
    [
      closeMobileMenu,
      favouritesCount,
      favouritesPage.root,
      hideFlyout,
      hideIndicator,
      isMobileLayout,
      isMountedOnCSR,
      like,
      showIndicator,
    ],
  );

  const renderReservationActionIcon = useMemo(
    () => (
      <Message code={['reservation.link.ariaLabel']}>
        {(reservationAriaLabel) => (
          <ActionIcon
            age
            ariaLabel={reservationAriaLabel}
            badge={reservationProductsCount}
            className={classnames({
              [styles.hideIcon]: !reservationProductsCount,
            })}
            dataPurpose="wxs.header.actions.reservation"
            glyph={reserve}
            isMobileLayout={isMobileLayout}
            link={`/${reservationPage.name}`}
            onBlur={hideIndicator}
            onFocus={showIndicator}
            onMouseEnter={showIndicator}
            onMouseLeave={hideIndicator}
          >
            {!hideFlyout && isMountedOnCSR && (
              <ReservationsMini reservationCarts={reservationCarts} />
            )}
          </ActionIcon>
        )}
      </Message>
    ),
    [
      hideFlyout,
      hideIndicator,
      isMobileLayout,
      isMountedOnCSR,
      reservationCarts,
      reservationPage.name,
      reservationProductsCount,
      reserve,
      showIndicator,
    ],
  );

  const renderCartActionIcon = useMemo(
    () => (
      <Message code={['cart.link.ariaLabel']}>
        {(cartAriaLabel) => (
          <ActionIcon
            ariaLabel={cartAriaLabel}
            badge={orderedProductsCount}
            dataPurpose="wxs.header.actions.cart"
            glyph={cartIcon}
            isMobileLayout={isMobileLayout}
            link={cartLink}
            onBlur={hideIndicator}
            onClick={closeMobileMenu}
            onFocus={showIndicator}
            onMouseEnter={showIndicator}
            onMouseLeave={hideIndicator}
          >
            {!cartsLoading && !hideFlyout && isMountedOnCSR && (
              <CartMini orderCarts={orderCarts} productsCount={orderedProductsCount} />
            )}
          </ActionIcon>
        )}
      </Message>
    ),
    [
      cartIcon,
      cartLink,
      cartsLoading,
      closeMobileMenu,
      hideFlyout,
      hideIndicator,
      isMobileLayout,
      isMountedOnCSR,
      orderCarts,
      orderedProductsCount,
      showIndicator,
    ],
  );

  const renderNonReduced = () => (
    <>
      {!isBurgerActive && (
        <div className={styles.actionIcons}>
          {isMountedOnCSR && (
            <>
              <HeaderLoginActionIcon
                isMobileLayout={isMobileLayout}
                noFlyout={hideFlyout}
                onBlur={hideIndicator}
                onFocus={showIndicator}
                onMouseEnter={showIndicator}
                onMouseLeave={hideIndicator}
              />
              <HeaderLogoutActionIcon
                isMobileLayout={isMobileLayout}
                noFlyout={hideFlyout}
                onBlur={hideIndicator}
                onFocus={showIndicator}
                onMouseEnter={showIndicator}
                onMouseLeave={hideIndicator}
              />
            </>
          )}
          {!skipGetFavourites && renderFavouritesActionIcon}
          {!skipGetReservations && !reservationLoading && renderReservationActionIcon}
          {!skipGetCarts && renderCartActionIcon}
        </div>
      )}
      {renderSearchBar}
      {isStoreFinderEnabled && !isMetaNavigationEnabled && renderStoreLocator()}
      {promotionEngine && (
        <div className={styles.promotionRemoved}>
          {showPromoFeedback &&
            promotionRemoved.map((promotion) => {
              const promotionArguments = {};
              promotion.arguments.forEach((argument, index) => {
                promotionArguments[`${index}`] = argument.value;
              });
              return (
                <FeedbackCard
                  dismissible
                  key={promotion.message}
                  onDismiss={() => setShowPromoFeedback(false)}
                  variant="warning"
                >
                  <Message code={promotion.message} values={promotionArguments}>
                    {(message) => <Content content={message} tag="span" />}
                  </Message>
                </FeedbackCard>
              );
            })}
        </div>
      )}
      {renderNav}
    </>
  );

  if (isModeHeadless || isMobileApp) {
    return null;
  }

  return (
    <header
      data-purpose="header"
      id="navigation-start"
      className={classnames(headerClass, {
        [styles.navFocus]: isNavFocused,
      })}
    >
      {isMetaNavigationEnabled && !isReduced && <MetaNavigation />}
      <ErrorBoundary>
        <div className={styles.headerWrapper}>
          <div className={styles.headerMenu}>
            {!isReduced && (
              <Hamburger
                closeMenu={closeMobileMenu}
                isBurgerActive={isBurgerActive}
                openMenu={openMobileMenu}
              />
            )}
            {renderLogoMessage}
            {!isBurgerActive && <SkipNavigation />}
            {isOverlayOpen && (
              <div
                className={styles.closeOverlayExpanded}
                data-purpose="header.overlay"
                onClick={closeOverlay}
                onKeyDown={handleKeyDown}
                role="presentation"
              />
            )}
            {!isReduced && renderNonReduced()}
          </div>
        </div>
      </ErrorBoundary>
      {hasIndicator && <Indicator leftOffset={indicatorLeftOffset} width={indicatorWidth} />}
    </header>
  );
};

Header.propTypes = {
  /** has Indicator */
  hasIndicator: PropTypes.bool,
};

Header.defaultProps = {
  hasIndicator: true,
};
export default Header;
