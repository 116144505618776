import React, { MutableRefObject, RefObject, useCallback } from 'react';
import { debouncing } from 'utils/function';
import {
  getFirstSubMenuItemElement,
  getLevelOrientation,
  getNextMenuItemElement,
  getParentMenuItemElement,
  getPreviousMenuItemElement,
  handleKeyboard,
} from 'components/Header/components/Navigation/Navigation.Dom';
import { InteractiveUIEventTypes } from 'components/Header/components/Navigation/Navigation.types';
import { HAMBURGER_BUTTON_PURPOSE } from 'components/Header/components/Hamburger/Hamburger';

import styles from 'components/Header/components/Navigation/Navigation.scss';

interface UseNavigationHooksProps {
  /** Callback to trigger when the mouse enters the navigation */
  allowHover: () => void;
  /** Whether the navigation is a hamburger navigation */
  isHamburgerNavigation?: boolean;
  /** Ref containing the hamburger navigation state */
  isHamburgerNavigationRef: MutableRefObject<boolean | undefined>;
  /** Ref containing the navigation element */
  navRef: RefObject<HTMLElement>;
  /** Callback to trigger when the hamburger navigation is closed */
  onCloseHamburger: (event?: React.MouseEvent) => void;
  /** Callback to trigger when the mouse enters a navigation item */
  onMouseEnter: (event: InteractiveUIEventTypes, itemId?: string) => void;
  /** Callback to trigger when the mouse leaves a navigation item */
  onMouseLeave: (event: InteractiveUIEventTypes) => void;
  /** Callback to set the navigation focus state */
  setIsNavFocused: (isFocused: boolean) => void;
}

const lastMetaLinkSelector = 'ul > li[data-purpose="metaNavigation.links"] > ul > li:last-child a';
export const GENERIC_LAST_LINK_SELECTOR = `header nav > ul > li:last-child > a, header nav > ${lastMetaLinkSelector}`;
const ACTIVE_NAVIGATION_CLASSNAME = 'activeMainNavigationItem';

const mouseEnter = debouncing((allowHover: () => void) => {
  allowHover();
}, 300);

const isInteractiveUIEvent = (event: any): event is InteractiveUIEventTypes =>
  // Check if event has properties that are common to all InteractiveUIEventTypes
  event &&
  typeof event.type === 'string' &&
  typeof event.clientX === 'number' &&
  typeof event.clientY === 'number';

const mouseLeave = debouncing((ref: HTMLElement) => {
  ref.removeAttribute('data-hover-delayed');
}, 500);

const TABINDEX_ACTIVE = '0';
const TABINDEX_INACTIVE = '-1';

const setTabIndex = (
  element: Element,
  isActive: typeof TABINDEX_INACTIVE | typeof TABINDEX_ACTIVE,
) => {
  const value = isActive === TABINDEX_INACTIVE ? TABINDEX_INACTIVE : TABINDEX_ACTIVE;
  element.setAttribute('tabIndex', value);

  if (isActive === TABINDEX_INACTIVE) {
    element.setAttribute('aria-hidden', 'true');
  } else {
    element.removeAttribute('aria-hidden');
  }
};

const isModifiedMouseClick = (event: InteractiveUIEventTypes): boolean =>
  event.ctrlKey || event.shiftKey || event.metaKey;

const setListTabIndexMeta = (listElements: Array<HTMLElement>) => {
  listElements.forEach((sibling) => {
    const listElement = sibling.firstChild as HTMLElement;

    if (sibling.getAttribute('data-purpose') === 'metaNavigation.links') {
      const metaLogin = sibling.firstChild?.firstChild as HTMLElement;
      metaLogin?.removeAttribute('inert');

      Array.from(listElement?.children || []).forEach((metaLinks: any) => {
        const elements = metaLinks.querySelectorAll('button, a');
        elements.forEach((element: Element) => {
          setTabIndex(element, TABINDEX_ACTIVE);
        });
      });
    } else {
      setTabIndex(listElement, TABINDEX_ACTIVE);
    }
  });
};

const ALLOWED_KEYS = ['Escape', 'Tab', 'Enter', 'Shift'];

const useNavigationHooks = ({
  allowHover,
  isHamburgerNavigation,
  isHamburgerNavigationRef,
  navRef,
  onCloseHamburger,
  onMouseEnter,
  onMouseLeave,
  setIsNavFocused,
}: UseNavigationHooksProps) => {
  const getFirstButtonInHeader = useCallback(
    () => document.querySelector('[data-purpose="header.button.hamburger"]') as HTMLButtonElement,
    [],
  );

  const getLastButtonInHeader = useCallback(() => {
    const activeMainNavigationItem = document.querySelectorAll('.activeMainNavigationItem');
    const lastlistChild = ':scope > ul > li:last-child';

    if (activeMainNavigationItem.length) {
      return activeMainNavigationItem[activeMainNavigationItem.length - 1].querySelector(
        `${lastlistChild} button, ${lastlistChild} a`,
      ) as HTMLButtonElement | HTMLAnchorElement;
    }
    return document.querySelector(GENERIC_LAST_LINK_SELECTOR) as HTMLAnchorElement;
  }, []);

  const handleRemoveHoverStatusImmediately = useCallback(
    (event: InteractiveUIEventTypes) => {
      if (isModifiedMouseClick(event)) {
        // do not trigger the menu removal on modified mouse clicks
        return;
      }

      navRef.current?.querySelectorAll('[data-hovered]').forEach((node: Element) => {
        node.removeAttribute('data-hovered');
      });

      onMouseLeave(event); // hides indicator

      if (
        document.activeElement instanceof HTMLAnchorElement &&
        navRef.current?.contains(document.activeElement)
      ) {
        document.activeElement.blur();
      }
    },
    [navRef, onMouseLeave],
  );

  const disableTabindex = useCallback((eventTarget: HTMLAnchorElement) => {
    const parentAnchorElement = eventTarget.parentElement?.firstChild as HTMLAnchorElement;

    // Set tabindex of parent anchor element to -1, so that it will be skipped when tabbing through the navigation
    setTabIndex(parentAnchorElement, TABINDEX_INACTIVE);

    const getListElements = Array.from(
      eventTarget?.parentElement?.parentElement?.children as HTMLCollectionOf<HTMLElement>,
    );

    // Iterate over each sibling element
    getListElements.forEach((listElement) => {
      const firstChild = listElement.firstChild as HTMLElement;
      // If the sibling list element is a meta navigation link, set tabindex of all its button and anchor children to -1
      if (listElement.getAttribute('data-purpose') === 'metaNavigation.links') {
        const metaLogin = listElement.firstChild?.firstChild as HTMLElement;
        metaLogin.setAttribute('inert', 'inert');

        Array.from(firstChild?.children || []).forEach((metaLinker: any) => {
          metaLinker.querySelectorAll('button, a').forEach((element: Element) => {
            setTabIndex(element, TABINDEX_INACTIVE);
          });
        });
      } else {
        // Otherwise, set tabindex of the first child to -1
        setTabIndex(firstChild, TABINDEX_INACTIVE);
      }
    });
  }, []);

  const handleFocusOnNextElement = useCallback((eventTarget: HTMLAnchorElement) => {
    // wait until changes in the DOM are really available
    requestIdleCallback(() => {
      // focus the overview element on the newly visible list
      const CategoryOverviewElement = eventTarget.nextElementSibling?.children[1] as HTMLLIElement;
      const CategoryOverviewElementLink = CategoryOverviewElement?.firstChild as HTMLAnchorElement;

      if (
        eventTarget?.dataset.purpose === 'navigation.navitem.link' &&
        CategoryOverviewElement &&
        CategoryOverviewElementLink
      ) {
        // Add timeout to give animation time to finish
        setTimeout(() => CategoryOverviewElementLink.focus(), 300);
      }
    });
  }, []);

  const handleScrollPosition = useCallback(() => {
    if (navRef.current) {
      // eslint-disable-next-line no-param-reassign
      (navRef.current.firstElementChild as HTMLElement).scrollTop = 0;
    }
  }, [navRef]);

  const handleNavigationLevel = useCallback(
    (eventTarget: HTMLAnchorElement, level: number) => {
      navRef.current?.classList.add(styles[`current-${level + 1}`]);
      styles[`current-${level}`] && navRef.current?.classList.remove(styles[`current-${level}`]);
      (eventTarget.parentElement as HTMLLIElement).classList.add(ACTIVE_NAVIGATION_CLASSNAME);
    },
    [navRef],
  );

  const handleClickItem = useCallback(
    (
      event: React.MouseEvent<Element, MouseEvent> | InteractiveUIEventTypes,
      hasSubItems: boolean,
      level: number,
    ) => {
      if (isHamburgerNavigation) {
        // fixes the scroll position that breaks the UI on iPhones
        handleScrollPosition();

        if (!hasSubItems || !navRef.current) {
          if (isInteractiveUIEvent(event) && isModifiedMouseClick(event)) {
            // do not trigger the menu removal on modified mouse clicks
            return;
          }

          onCloseHamburger(event as React.MouseEvent);
          return;
        }

        const eventTarget = event.currentTarget;

        if (eventTarget instanceof HTMLAnchorElement) {
          if (eventTarget.getAttribute('data-purpose') !== 'metaNavigation.links') {
            event.preventDefault();
          }

          handleNavigationLevel(eventTarget, level);
          disableTabindex(eventTarget);
          handleFocusOnNextElement(eventTarget);
        }
      }
    },
    [
      disableTabindex,
      handleFocusOnNextElement,
      handleNavigationLevel,
      handleScrollPosition,
      isHamburgerNavigation,
      navRef,
      onCloseHamburger,
    ],
  );

  const closeDesktopNavigation = useCallback(
    (event: KeyboardEvent) => {
      if (!navRef.current?.contains(document.activeElement)) {
        handleRemoveHoverStatusImmediately(event);
      }
    },
    [handleRemoveHoverStatusImmediately, navRef],
  );

  const handleMouseEnter = useCallback(
    (itemId?: string) => (event: InteractiveUIEventTypes) => {
      mouseLeave.cancel();
      mouseEnter(allowHover);
      onMouseEnter(event, itemId);
    },
    [allowHover, onMouseEnter],
  );

  const handleTab = (event: any, nextMenuItemElement: any, previousMenuItemElement: any) => {
    // Filter out the buttons that contain an <i> element
    const buttonsInLI = (event.target.parentElement as HTMLLIElement).querySelectorAll('button');
    const buttonWithChevron = Array.from(buttonsInLI).filter(
      (button) => button?.querySelector('i') !== null,
    );
    const isButtonWithChevron = event.target.nodeName === 'BUTTON';
    const parentLI = event.target.parentElement;
    const isLastElementInList =
      isButtonWithChevron && !(parentLI as HTMLLIElement)?.nextElementSibling;
    // control forward navigation
    if (!event.shiftKey) {
      if (isButtonWithChevron && !isLastElementInList) {
        (event.target as HTMLAnchorElement).parentElement?.removeAttribute('data-hover-delayed');
        (event.target as HTMLAnchorElement).parentElement?.removeAttribute('data-hovered');
        nextMenuItemElement?.setAttribute('data-hover-delayed', 'true');
        nextMenuItemElement?.setAttribute('data-hovered', 'true');
        (nextMenuItemElement?.firstChild as HTMLAnchorElement)?.focus();
        return;
      }

      buttonWithChevron[0]?.focus();
      return;
    }
    // control backward navigation
    // if its a chevron button focus on its anchor sibling
    if (isButtonWithChevron) {
      ((event.target as HTMLLIElement).parentElement?.firstChild as HTMLAnchorElement)?.focus();

      return;
    }
    // focus on the previous button with chevron unless there is no more visible LIs
    if (previousMenuItemElement) {
      parentLI?.removeAttribute('data-hover-delayed');
      parentLI?.removeAttribute('data-hovered');
      previousMenuItemElement?.setAttribute('data-hover-delayed', 'true');
      previousMenuItemElement?.setAttribute('data-hovered', 'true');
      (previousMenuItemElement?.children[1] as HTMLElement).focus();
    } else {
      // focus on parent LI thats renders the UL you just got out from
      (
        (event.target as HTMLAnchorElement).parentElement?.parentElement?.closest(
          'li[data-hovered]',
        )?.firstChild as HTMLAnchorElement
      ).focus();
    }
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;

      if (!ALLOWED_KEYS.includes(key)) {
        // early return for performance.
        return;
      }

      if (key === 'Escape') {
        handleRemoveHoverStatusImmediately(event);
        return;
      }

      const eventTarget = event.target as HTMLAnchorElement;
      const nextMenuItemElement = getNextMenuItemElement(event);
      const immediateParent = eventTarget.parentElement as HTMLLIElement;
      const currentLevel = parseInt(immediateParent.getAttribute('data-level') || '0', 10);
      const levelOrientation = getLevelOrientation(
        currentLevel,
        !!isHamburgerNavigationRef.current,
      );

      const parentMenuItemElement = getParentMenuItemElement(event, currentLevel);
      const previousMenuItemElement = getPreviousMenuItemElement(
        event,
        !!isHamburgerNavigationRef.current,
      );
      const firstSubMenuItemElement = getFirstSubMenuItemElement(event);

      if (key === 'Tab' && !isHamburgerNavigationRef.current && currentLevel === 1) {
        event.preventDefault();

        handleTab(event, nextMenuItemElement, previousMenuItemElement);
        return;
      }

      if (key === 'Enter') {
        navRef?.current?.setAttribute('data-hover-delayed', 'true');
        if (currentLevel === 1 && document.activeElement?.nodeName === 'BUTTON') {
          (firstSubMenuItemElement?.firstChild as HTMLAnchorElement)?.focus();
          return;
        }
      }

      handleKeyboard[levelOrientation](
        event,
        key,
        !!isHamburgerNavigationRef.current,
        parentMenuItemElement as HTMLLIElement,
        previousMenuItemElement as HTMLLIElement,
        firstSubMenuItemElement as HTMLLIElement,
        nextMenuItemElement as HTMLLIElement,
      );
    },
    [handleRemoveHoverStatusImmediately, isHamburgerNavigationRef, navRef],
  );

  const handleMouseLeave = useCallback(
    (event: InteractiveUIEventTypes) => {
      mouseEnter.cancel();
      setIsNavFocused(false);
      navRef.current && mouseLeave(navRef.current);
      onMouseLeave(event);
    },
    [navRef, onMouseLeave, setIsNavFocused],
  );

  const findCurrentRelevantItems = useCallback(
    (event: InteractiveUIEventTypes | React.PointerEvent) => {
      const closestParent = (event.currentTarget as HTMLAnchorElement).closest(
        `.${ACTIVE_NAVIGATION_CLASSNAME}`,
      );
      const parentAnchorElement = closestParent?.firstChild as HTMLElement;
      const getListElements = Array.from(
        closestParent?.parentElement?.children as HTMLCollectionOf<HTMLElement>,
      );
      return { closestParent, getListElements, parentAnchorElement };
    },
    [],
  );

  const onClickLevelBack = useCallback(
    (event: InteractiveUIEventTypes | React.PointerEvent, currentLevel: number) => {
      navRef.current?.classList.remove(styles[`current-${currentLevel}`]);
      navRef.current?.classList.add(styles[`current-${currentLevel - 1}`]);

      const { closestParent, getListElements, parentAnchorElement } =
        findCurrentRelevantItems(event);

      setListTabIndexMeta(getListElements);
      closestParent?.classList.remove(ACTIVE_NAVIGATION_CLASSNAME);
      setTabIndex(parentAnchorElement, TABINDEX_ACTIVE);

      // Add timeout to give animation time to finish
      setTimeout(() => parentAnchorElement?.focus(), 300);
    },
    [findCurrentRelevantItems, navRef],
  );

  const handleMobileMenuFocus = useCallback(
    (event: KeyboardEvent) => {
      const hamburgerMenuButton = document.querySelector(
        `button[data-purpose="${HAMBURGER_BUTTON_PURPOSE}"]`,
      );

      if (event.shiftKey && event.key === 'Tab') {
        if (document.activeElement === hamburgerMenuButton) {
          event.preventDefault();
          getLastButtonInHeader()?.focus();
        }
        return;
      }
      if (event.key === 'Tab') {
        if (document.activeElement === getLastButtonInHeader()) {
          event.preventDefault();
          getFirstButtonInHeader()?.focus();
        }
      }
    },
    [getFirstButtonInHeader, getLastButtonInHeader],
  );

  return {
    closeDesktopNavigation,
    handleClickItem,
    handleKeyDown,
    handleMobileMenuFocus,
    handleMouseEnter,
    handleMouseLeave,
    handleRemoveHoverStatusImmediately,
    onClickLevelBack,
  };
};

export default useNavigationHooks;
