import { NAVIGATION_QUERY } from 'components/Header/components/Navigation/Navigation.query';
import {
  MAX_NESTED_LEVELS,
  MAX_RETRY_COUNT,
  RETRY_TIMEOUT,
} from 'components/Header/components/Navigation/Navigation.config';
import { ApolloClient } from '@apollo/client/core';
import { NormalizedCacheObject } from '@apollo/client/cache/inmemory/types';

let currentRetries = 0;

const populateToCache: any = async (
  client: ApolloClient<NormalizedCacheObject>,
  code: string,
  currentLevel: number,
  items: any[],
  isInitialPreload: boolean,
  isMobileView: boolean,
) => {
  const variables = {
    codes: [code],
    levelAmount: 1,
    levelOffset: currentLevel,
  };

  const cachedData = await client.readQuery({
    query: NAVIGATION_QUERY,
    variables,
  });

  const processedItems = items.map((item, index) => {
    if (isInitialPreload && !isMobileView && currentLevel === 1 && index === 0) {
      preloadSubItems(client, item.code, currentLevel + 1, isMobileView, true);
    }

    if (item.subItems.length > 0) {
      populateToCache(
        client,
        item.code,
        currentLevel + 1,
        item.subItems,
        isInitialPreload,
        isMobileView,
      );
    }

    // since we don't need the actual subItems because every level is treated separately, we delete them
    // this reduces the footprint of cachedData
    return { ...item, subItems: [] };
  });

  if (!cachedData) {
    client.writeQuery({
      query: NAVIGATION_QUERY,
      variables,
      data: {
        navigation: {
          data: {
            main: [...processedItems],
          },
        },
      },
    });
  }
};

// will only be called on client side
const preloadSubItems: any = async (
  client: any,
  code: string,
  currentLevel: number,
  isMobileView: boolean,
  loadAllSubLevels?: boolean,
) => {
  let levelAmount = 2;

  if (loadAllSubLevels) {
    levelAmount = MAX_NESTED_LEVELS + 1 - currentLevel;
  }

  const variables = {
    codes: [code],
    levelAmount,
    levelOffset: currentLevel,
  };

  const cachedData = await client.readQuery({
    query: NAVIGATION_QUERY,
    variables,
  });

  if (!cachedData) {
    const { data, errors } = await client.query({
      query: NAVIGATION_QUERY,
      variables,
      fetchPolicy: 'cache-first',
    });

    if (errors?.length) {
      currentRetries += 1;

      if (currentRetries < MAX_RETRY_COUNT) {
        setTimeout(() => {
          preloadSubItems(client, code, currentLevel, isMobileView, loadAllSubLevels);
        }, RETRY_TIMEOUT);
      }

      return;
    }

    const mainData = data?.navigation?.data.main || [];

    populateToCache(client, code, currentLevel, mainData, code === 'header_nav_root', isMobileView);
  }
};

export default preloadSubItems;
