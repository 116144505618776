import React, { FC, MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { microphone, microphoneDeactivated, arrowRight } from '@xxxlgroup/hydra-icons';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import useMessage from 'components/Message/useMessage';
import classnames from 'classnames';
import track from 'react-tracking';
import { IconButton, Modal, Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';
import useLanguage from 'hooks/useLanguage';
import Link from 'components/WebshopLink';
import { useCategoryContext } from 'pages/CategoryView/provider/Category.provider';
import { isAndroidDevice } from 'utils/deviceMatchers';

import styles from 'components/Header/components/SpeechToTextButton/SpeechToTextButton.scss';

type State =
  | 'isMicEnabled'
  | 'isWaitingForMicPermission'
  | 'isMicDisabledByUser'
  | 'isTimeout'
  | null;

const TIME_TO_STOP_RECORDING = 6000;

const SpeechToTextButton: FC<{
  handleSubmit: (text: string) => (event: MouseEvent | SpeechRecognitionEvent | Event) => void;
  setIsVoiceSearch: (isVoiceSearch: boolean) => void;
  setTranscribedText: (text: string) => void;
}> = (props) => {
  const { setTranscribedText, handleSubmit, setIsVoiceSearch } = props;
  const tracking = useTracking(props, 'SpeechToTextButton');
  const { code } = useLanguage();
  const listeningTimer = useRef<number>(0);
  const [state, setState] = useState<State>(null);
  const { searchTracking } = useCategoryContext();

  const [
    allowAccess,
    close,
    voiceSearch,
    microphoneIsDisabledWarning,
    permissionsInstructions,
    recording,
    voiceNotRecognized,
    tryAgain,
  ] = useMessage([
    'voicesearchfeature.allow.access.text',
    'wxs.modal.closeButton.ariaLabel',
    'voicesearchfeature.voicesearch.text',
    'voicesearchfeature.disabled.microphone.text',
    'voicesearchfeature.permissions.instructions.text',
    'voicesearchfeature.recording.text',
    'voicesearchfeature.voice.not.recognized.text',
    'voicesearchfeature.search.again.text',
  ]);
  const [micDeactivatedStyle, micDeactivatedClassBefore] = pseudoIcon(microphoneDeactivated);
  const [micStyle, micClassBefore] = pseudoIcon(microphone);
  const [recognition, setRecognition] = useState<Partial<SpeechRecognition> | null>(null);

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    SpeechRecognition && setRecognition(new SpeechRecognition());

    return () => {
      clearTimeout(listeningTimer.current);
    };
  }, []);

  const stopRecording = useCallback(() => {
    recognition?.stop?.();
    clearTimeout(listeningTimer.current);
    listeningTimer.current = 0;
  }, [recognition]);

  const closeModal = useCallback(() => {
    stopRecording();
    setState(null);
  }, [stopRecording]);

  const startRecording = useCallback(async () => {
    if (!recognition) {
      return;
    }
    setState('isWaitingForMicPermission');

    recognition.lang = code;

    // iOS does not work unless interimResults and continuous are set to true and the opposite happens to android
    if (!isAndroidDevice()) {
      recognition.interimResults = true;
      recognition.continuous = true;
    }

    let stagedTranscript = '';

    recognition.onstart = (event: Event) => {
      setState('isMicEnabled');

      document.activeElement instanceof HTMLElement && document.activeElement.blur();

      listeningTimer.current = setTimeout(() => {
        // we want to avoid timing out if the user said something
        if (stagedTranscript) {
          setTranscribedText(stagedTranscript);
          stopRecording();
          setState(null);
          searchTracking.current.method = 'microphone';
          searchTracking.current.term = stagedTranscript;
          handleSubmit(stagedTranscript)(event);
        } else {
          setState('isTimeout');
          recognition?.abort?.();

          tracking(event, {
            type: 'voiceSearch',
            event: { type: 'error', purpose: 'search.bar.interactions' },
            props: { action: 'Error voice is not recognizable' },
          });
        }
        clearTimeout(listeningTimer.current);
      }, TIME_TO_STOP_RECORDING);
    };

    recognition.onresult = (event: SpeechRecognitionEvent) => {
      const { transcript } = event.results[0][0];
      const { isFinal } = event.results[0];
      stagedTranscript = transcript;

      if (isFinal) {
        clearTimeout(listeningTimer.current);
        setTranscribedText(transcript);
        stopRecording();
        setState(null);
        searchTracking.current.method = 'microphone';
        handleSubmit(transcript)(event);
      }
    };

    recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
      if (
        state === 'isTimeout' &&
        event.message === 'No speech detected' &&
        event.error !== 'aborted'
      ) {
        clearTimeout(listeningTimer.current);
        tracking(event, {
          type: 'voiceSearch',
          event: { type: 'error', purpose: 'search.bar.interactions' },
          props: { action: 'Error voice is not recognizable' },
        });
      }
      if (
        event.message.includes('permission check has failed') ||
        event.message === 'service-not-allowed' ||
        event.error === 'not-allowed'
      ) {
        setState('isMicDisabledByUser');
        tracking(event, {
          type: 'voiceSearch',
          event: { type: 'error', purpose: 'search.bar.interactions' },
          props: { action: 'Error microphone not allowed' },
        });
      }
      recognition?.stop?.();
    };

    recognition.start?.();
  }, [
    code,
    handleSubmit,
    recognition,
    searchTracking,
    setTranscribedText,
    state,
    stopRecording,
    tracking,
  ]);

  const handleClick: MouseEventHandler = useCallback(
    (event) => {
      tracking(event, {
        type: 'voiceSearch',
        event: { type: 'click', purpose: 'search.bar.interactions' },
        props: { action: 'Use microphone' },
      });

      setIsVoiceSearch(true);

      try {
        startRecording();
      } catch (e) {
        setState('isMicDisabledByUser');
      }
    },
    [setIsVoiceSearch, startRecording, tracking],
  );

  if (!recognition) {
    return null;
  }

  return (
    <>
      <div
        data-purpose="header.searchBar.speechToText.wrapper"
        className={classnames(styles.iconWrapper)}
      >
        <IconButton
          onClick={handleClick}
          data-purpose="header.searchBar.speechToText"
          className={styles.microphoneButton}
          glyph={microphone}
        />
      </div>
      {state && (
        <Modal className={styles.modal} heading={voiceSearch} i18n={{ close }} onClose={closeModal}>
          {state === 'isMicEnabled' && (
            <>
              <p className={styles.modalText}>{recording}</p>
              <div className={classnames(styles.modalMicrophoneWrapper, styles.recordingWrapper)}>
                <div
                  style={micStyle}
                  className={classnames(micClassBefore, styles.microphone, styles.recording)}
                />
              </div>
            </>
          )}
          {state === 'isTimeout' && (
            <>
              <p className={styles.modalText}>
                {voiceNotRecognized}
                <HydraLink
                  glyphAfter={arrowRight}
                  layout="block"
                  onClick={handleClick}
                  theme="coal"
                  typography="regular"
                  underline
                >
                  {tryAgain}
                </HydraLink>
              </p>
              <button
                className={classnames(styles.modalMicrophoneWrapper, styles.isTimeout)}
                onClick={handleClick}
              >
                <div
                  style={micStyle}
                  className={classnames(micClassBefore, styles.microphone, styles.recording)}
                />
              </button>
            </>
          )}
          {state === 'isWaitingForMicPermission' && (
            <>
              <p className={styles.modalText}>{allowAccess}</p>
              <div
                className={classnames(styles.modalMicrophoneWrapper, styles.waitingForPermission)}
              >
                <div style={micStyle} className={classnames(micClassBefore, styles.microphone)} />
              </div>
            </>
          )}
          {state === 'isMicDisabledByUser' && (
            <>
              <p className={styles.modalText}>
                {microphoneIsDisabledWarning}
                <Link
                  target="_blank"
                  href="https://support.google.com/chrome/answer/2693767?visit_id=638333750527689286-761904054&p=ui_voice_search&rd=1&hl=de"
                  as={
                    <HydraLink
                      glyphAfter={arrowRight}
                      layout="block"
                      typography="regular"
                      underline
                      theme="coal"
                    />
                  }
                >
                  {permissionsInstructions}
                </Link>
              </p>
              <div className={classnames(styles.modalMicrophoneWrapper, styles.deactivatedWrapper)}>
                <div
                  style={micDeactivatedStyle}
                  className={classnames(
                    micDeactivatedClassBefore,
                    styles.microphone,
                    styles.permissionDenied,
                  )}
                />
              </div>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default track(tagComponent('SpeechToTextButton'))(SpeechToTextButton);
