import React, { useCallback, useState } from 'react';
import { noop } from '@xxxlgroup/hydra-utils/common';
import PropTypes from 'prop-types';
import Link from 'components/WebshopLink';
import LoginModal from 'modules/customer-authentication/components/LoginModal/LoginModal';
import { Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import useMessage from 'components/Message/useMessage';
import { useTracking } from 'utils/tracking/hooks';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import useRoutes from 'hooks/useRoutes';

const LoginLink = (props) => {
  const { onLogin, className, withUnderline, layout, typography, theme } = props;
  const tracking = useTracking(props, 'NavigationItem');
  const loginMessage = useMessage('wxs.user.login.button');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    customerAuthentication: { signIn },
  } = useRoutes();

  const handleOnToggleModal = useCallback(
    (event) => {
      setIsModalOpen(!isModalOpen);
      tracking(event, { parentLevels: { level: 0, text: 'Login' } });
    },
    [isModalOpen, tracking],
  );

  if (props.isInline) {
    return (
      <Link
        as={
          <HydraLink
            className={className}
            layout={layout}
            theme={theme}
            typography={typography}
            underline={withUnderline}
          />
        }
        className={className}
        href={`/${signIn.name}`}
      >
        {loginMessage}
      </Link>
    );
  }

  return (
    <>
      <HydraLink
        onClick={handleOnToggleModal}
        className={className}
        underline={withUnderline}
        layout={layout}
        typography={typography}
        theme={theme}
      >
        {loginMessage}
      </HydraLink>
      {isModalOpen && <LoginModal onClose={handleOnToggleModal} onLogin={onLogin} />}
    </>
  );
};

LoginLink.propTypes = {
  /** @ignore */
  className: PropTypes.string,
  /** is inline text link */
  isInline: PropTypes.bool,
  /** Determines how the link will allocated horizontal space */
  layout: PropTypes.oneOf(['inline', 'block']),
  /* Callback to be called when login process ends. */
  onLogin: PropTypes.func,
  /** Sets the theme for the link */
  theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  /** Sets link text size */
  typography: PropTypes.oneOf(['xxsmall', 'xsmall', 'small', 'regular']),
  /** Indicates whether the Link has a underline in its normal state */
  withUnderline: PropTypes.bool,
};

LoginLink.defaultProps = {
  className: null,
  layout: 'inline',
  onLogin: noop,
  theme: 'primary',
  typography: 'xxsmall',
  withUnderline: false,
  isInline: false,
};

export default track(tagComponent('NavigationItem'))(LoginLink);
