import React, { FC, EventHandler, MouseEventHandler, SyntheticEvent } from 'react';
import classnames from 'classnames';
import { arrowUp } from '@xxxlgroup/hydra-icons';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { getSearchUrl } from 'utils/url';
import { highlightSearch } from 'components/Header/components/Suggest/Suggest.utils';
import suggestStyles from 'components/Header/components/Suggest/Suggest.scss';
import useMessage from 'components/Message/useMessage';
import Link from 'components/WebshopLink';
import styles from 'components/Header/components/Suggest/components/KeywordItems/components/KeywordItem/KeywordItem.scss';

type Item = {
  id: string;
  label: string;
  url?: string;
};

interface KeywordItemProps {
  /** Handle click callback */
  handleClick: MouseEventHandler<HTMLAnchorElement>;
  /** Handle hover callback */
  handleHover: EventHandler<SyntheticEvent<HTMLButtonElement>>;
  /** Items to display */
  item: Item;
  /** Search term to highlight  */
  searchTerm: string;
  /** Type ahead callback */
  typeAhead: (searchTerm: string) => MouseEventHandler<HTMLButtonElement>;
}

const getSuggestedKeywordUrl = (suggestedKeyword: Item) => {
  if (suggestedKeyword.url) {
    const url = new URL(suggestedKeyword.url);
    return url.pathname + url.search;
  }
  return getSearchUrl(suggestedKeyword.label);
};

const KeywordItem: FC<KeywordItemProps> = ({
  item,
  handleClick,
  handleHover,
  searchTerm,
  typeAhead,
}) => {
  const [messageLabel] = useMessage(['wxs.header.suggest.icon.ariaLabel'], {}, true);
  const [iconStyle, iconClassName] = pseudoIcon(arrowUp);

  return (
    <li className={suggestStyles.item}>
      <Link
        className={suggestStyles.link}
        data-purpose="header.suggest.keyword.link"
        data-track-id="suggestSearchTerm"
        href={getSuggestedKeywordUrl(item)}
        onClick={handleClick}
      >
        {highlightSearch(item.label, searchTerm)}
      </Link>
      <button
        aria-label={messageLabel}
        className={classnames(styles.typeAhead, iconClassName)}
        data-purpose="header.suggest.button.typeAhead"
        onClick={typeAhead(item.label)}
        onFocus={handleHover}
        onMouseEnter={handleHover}
        style={iconStyle}
        type="button"
      />
    </li>
  );
};

export default KeywordItem;
