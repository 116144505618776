import React from 'react';
import { Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { chevronRight } from '@xxxlgroup/hydra-icons';
import LoginLink from 'modules/customer-authentication/components/LoginLink';
import useMessage from 'components/Message/useMessage';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import { useTracking } from 'utils/tracking/hooks';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import useRoutes from 'hooks/useRoutes';
import Link from 'components/WebshopLink/WebshopLink';
import AccountGreeting from 'modules/customer-account/components/AccountGreeting';
import { MyAccountTeaserProps } from 'components/Header/components/Navigation/Navigation.types';

import styles from 'components/Header/components/Navigation/components/MyAccountTeaser/MyAccountTeaser.scss';

const MyAccountTeaser = (props: MyAccountTeaserProps) => {
  const { onCloseHamburger = noop } = props;
  const myAccountMessage = useMessage('account.myAccount.link');
  const { customerAccount } = useRoutes();
  const isAccountEnabled = useFeatureFlagsEnabled('poseidon.account.enabled');
  const { isAnonymous, isPoseidonLoginEnabled } = useAuth();
  const showAccountFeature = isPoseidonLoginEnabled && isAccountEnabled;
  const tracking = useTracking(props, 'NavigationItem');

  if (!showAccountFeature) {
    return null;
  }

  const handleClick = (event: React.PointerEvent<Element>) => {
    onCloseHamburger(event);
    tracking(event, { parentLevels: { level: 0, text: 'My Account' } });
  };

  return isAnonymous ? (
    <div className={styles.teaserLogin}>
      <span className={styles.teaserLoginMessage}>{myAccountMessage}</span>
      <LoginLink isInline />
    </div>
  ) : (
    <Link
      className={styles.myAccountWrapper}
      onClick={handleClick}
      href={`/${customerAccount.name}`}
      as={
        <HydraLink
          layout="inline"
          typography="small"
          theme="coal"
          className={styles.teaserAccount}
          glyphAfter={chevronRight}
        />
      }
    >
      <span className={styles.myAccountMessage}>{myAccountMessage}</span> <br />
      <AccountGreeting headingClassName={styles.myAccountContact} />
    </Link>
  );
};

export default track(tagComponent('NavigationItem'))(MyAccountTeaser);
