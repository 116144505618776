import React, { useCallback, useEffect, useState, useRef, memo } from 'react';
import { useApolloClient } from '@apollo/client';
import classnames from 'classnames';
import track from 'react-tracking';
import { noop } from '@xxxlgroup/hydra-utils/common';

import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';

import {
  InteractiveUIEventTypes,
  NavigationItemProps,
} from 'components/Header/components/Navigation/Navigation.types';
import preloadSubItems from 'components/Header/components/Navigation/utils/preloadSubItems';
import NavigationList from 'components/Header/components/Navigation/components/NavigationList';
import NavigationItemLink from 'components/Header/components/Navigation/components/NavigationItemLink';

import styles from 'components/Header/components/Navigation/components/NavigationItem/NavigationItem.scss';

import {
  LEVELS_WITH_ACTIVATED_FIRST_SUB_ITEM,
  LEVELS_WITH_HOVER,
  LEVELS_WITH_PRELOAD_ON_HOVER,
  MAX_RENDERED_SUB_ITEMS,
  MAX_RENDERED_SUB_ITEMS_IF_SPACE_AVAILABLE,
  SUBMENU_HIT_ALLOWED_MOUSE_MOVE_ANGLE,
  SUBMENU_HIT_MOUSE_MOVE_DETECTION_TIMEOUT,
} from 'components/Header/components/Navigation/Navigation.config';

// Note: treat levels without hover status as if they already have one to trigger a click immediately
const hasHoverStatus = (currentElement: HTMLLIElement, level: number): boolean =>
  currentElement.hasAttribute('data-hovered') || LEVELS_WITH_HOVER.includes(level);

const shouldPreventDefault = (
  currentElement: HTMLLIElement,
  level: number,
  hasSubItems: boolean,
): boolean =>
  !currentElement.hasAttribute('data-hovered') && LEVELS_WITH_HOVER.includes(level) && hasSubItems;

const collectParentLevels = (htmlElement: HTMLLIElement, startingLevel: number) => {
  let currentLevel = startingLevel;
  let currentText;
  const levels = [];
  let parent: HTMLElement | null = htmlElement;

  while (currentLevel > 0) {
    currentLevel = parseInt(parent?.dataset.level || '0', 10);
    currentText = (parent?.firstElementChild as HTMLElement)?.innerText ?? '';
    levels.push({ level: currentLevel, text: currentText });

    parent = parent?.parentElement?.parentElement ?? null;
  }

  return levels;
};

const NavigationItem = (props: NavigationItemProps) => {
  const {
    className,
    code,
    hasOnlyOneSubItem,
    hasSubItems,
    id,
    isNavFocused = false,
    setIsNavFocused = noop,
    isHamburgerNavigation,
    isHighlightLink,
    isOverview,
    layoutType = 'standard',
    level = 0,
    link,
    name,
    onClickItem = noop,
    onClickLevelBack = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
    onRemoveHoverStatusImmediately = noop,
    renderAllSubLevels,
    svg,
  } = props;
  const client = useApolloClient();

  const touchOutsideHandlerSet = useRef(false);

  const tracking = useTracking(props, 'NavigationItem');
  const parentItem = {
    code,
    id,
    layoutType,
    link,
    name,
    svg,
  };

  const elementToGetHoveredSoon = useRef<HTMLLIElement | null>(null);
  const setTimeoutReference = useRef<ReturnType<typeof setTimeout> | null>(null);
  const navDom = useRef<HTMLElement | null>(null);
  const listElement = useRef<HTMLLIElement | null>(null);
  const oldX = useRef(0);
  const oldY = useRef(0);

  const [renderNextLevel, setRenderNextLevel] = useState(renderAllSubLevels);

  useEffect(() => {
    if (listElement.current) {
      navDom.current = listElement.current.closest('nav');
    }
  }, []);

  /* eslint no-use-before-define: 0 */
  const setHoverStatus = useCallback(
    (currentListItemElement: HTMLLIElement) => {
      // remove hover on other siblings and their children
      const currentULElement = currentListItemElement.parentElement;

      // find siblings and their children with hover status to deactivate them.
      currentULElement?.querySelectorAll('[data-hovered]').forEach((node) => {
        if (node !== currentListItemElement) {
          node.removeAttribute('data-hovered');
        }
      });

      currentListItemElement.setAttribute('data-hovered', 'true');

      if (level === 0 && !touchOutsideHandlerSet.current) {
        // add event listener for touch outside
        document.body.addEventListener('touchstart', checkIfTouchedOutside);
        touchOutsideHandlerSet.current = true;
      }

      if (LEVELS_WITH_ACTIVATED_FIRST_SUB_ITEM.includes(level)) {
        // nth-child(3) because for mobile the first child is the "back" button and the second is the current menu.
        const firstSubItem = currentListItemElement.querySelector('ul > li:nth-child(3)');

        if (firstSubItem) {
          firstSubItem.setAttribute('data-hovered', 'true');
        }
      }

      if (elementToGetHoveredSoon.current) {
        elementToGetHoveredSoon.current.removeEventListener('mousemove', handleMouseMove);
      }

      if (LEVELS_WITH_PRELOAD_ON_HOVER.includes(level)) {
        preloadSubItems(client, code, level + 1, false, true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      const diffX = event.pageX - oldX.current;
      const diffY = Math.abs(event.pageY - oldY.current);
      // calculate the angle in which the user can move to the subNavigation
      const degree = (Math.atan(diffY / Math.abs(diffX)) * 180) / Math.PI;

      if (
        (diffX < 0 || degree > SUBMENU_HIT_ALLOWED_MOUSE_MOVE_ANGLE) &&
        setTimeoutReference.current &&
        elementToGetHoveredSoon.current
      ) {
        clearTimeout(setTimeoutReference.current);
        setTimeoutReference.current = null;

        setHoverStatus(elementToGetHoveredSoon.current);
      } else if (setTimeoutReference.current) {
        clearTimeout(setTimeoutReference.current);

        setTimeoutReference.current = setTimeout(() => {
          if (elementToGetHoveredSoon.current) {
            setHoverStatus(elementToGetHoveredSoon.current);
          }
        }, SUBMENU_HIT_MOUSE_MOVE_DETECTION_TIMEOUT);
      }

      oldX.current = event.pageX;
      oldY.current = event.pageY;
    },
    [setHoverStatus],
  );

  const handleMouseLeave = useCallback(
    (event: InteractiveUIEventTypes) => {
      onMouseLeave(event);

      if (elementToGetHoveredSoon.current) {
        elementToGetHoveredSoon.current.removeEventListener('mousemove', handleMouseMove);
      }

      if (setTimeoutReference.current) {
        clearTimeout(setTimeoutReference.current);
        setTimeoutReference.current = null;
      }
    },
    [elementToGetHoveredSoon, handleMouseMove, onMouseLeave],
  );

  const checkIfTouchedOutside = useCallback(
    (event: TouchEvent) => {
      if (!navDom.current?.contains(event.target as HTMLElement)) {
        handleMouseLeave(event);
        onRemoveHoverStatusImmediately(event);

        if (touchOutsideHandlerSet.current && !CONFIG.IS_SSR) {
          document.body.removeEventListener('touchstart', checkIfTouchedOutside);
          touchOutsideHandlerSet.current = false;
        }
      }
    },
    [handleMouseLeave, onRemoveHoverStatusImmediately],
  );

  const handleClick = useCallback(
    (event: React.PointerEvent<HTMLAnchorElement>) => {
      const parentLevels = collectParentLevels(
        (event.target as HTMLAnchorElement).parentElement as HTMLLIElement,
        level,
      );
      tracking(event, { parentLevels });

      if (!isHamburgerNavigation) {
        onRemoveHoverStatusImmediately(event);

        if (touchOutsideHandlerSet.current) {
          document.body.removeEventListener('touchstart', checkIfTouchedOutside);
          touchOutsideHandlerSet.current = false;
        }
      } else if (hasSubItems) {
        preloadSubItems(client, code, level + 1, true, true);
      }

      onClickItem(event, !!hasSubItems, level);
    },
    [
      checkIfTouchedOutside,
      client,
      code,
      hasSubItems,
      isHamburgerNavigation,
      level,
      onClickItem,
      onRemoveHoverStatusImmediately,
      tracking,
    ],
  );

  const handleFocus = useCallback(
    (event: React.FocusEvent) => {
      // suppress the delay immediately
      if (navDom.current) {
        navDom.current.setAttribute('data-hover-delayed', 'true');
      }
      setRenderNextLevel(true);

      if (!isHamburgerNavigation) {
        // treat the focus as if it is touched
        setIsNavFocused(true);
        setHoverStatus((event.currentTarget as HTMLElement).parentElement as HTMLLIElement);
      }
    },
    [isHamburgerNavigation, setHoverStatus, setIsNavFocused],
  );

  const handleMouseEnter = useCallback(
    (event: InteractiveUIEventTypes) => {
      tracking(event);
      onMouseEnter(event);
      setRenderNextLevel(true);

      if (!isHamburgerNavigation) {
        const currentTarget = event.currentTarget as HTMLLIElement;

        // set the hover state of the dom nodes programmatically
        if (currentTarget.tagName === 'LI' && level === 1) {
          const reactMouseEvent = event as React.MouseEvent;
          oldX.current = reactMouseEvent.pageX;
          oldY.current = reactMouseEvent.pageY;

          elementToGetHoveredSoon.current = currentTarget;

          setTimeoutReference.current = setTimeout(() => {
            setHoverStatus(elementToGetHoveredSoon.current as HTMLLIElement);
          }, SUBMENU_HIT_MOUSE_MOVE_DETECTION_TIMEOUT);

          elementToGetHoveredSoon.current.addEventListener('mousemove', handleMouseMove);
        } else {
          setHoverStatus(currentTarget);
        }
      }
    },
    [
      elementToGetHoveredSoon,
      handleMouseMove,
      isHamburgerNavigation,
      level,
      onMouseEnter,
      setHoverStatus,
      tracking,
    ],
  );

  const handleTouchEnd = useCallback(
    (event: InteractiveUIEventTypes) => {
      const currentListItemElement = (event.currentTarget as HTMLAnchorElement)
        .parentElement as HTMLLIElement;
      // suppress the delay immediately
      if (navDom.current) {
        navDom.current.setAttribute('data-hover-delayed', 'true');
      }

      if (!isHamburgerNavigation) {
        // avoids the top level link from navigating to category page without showing dropdown menu unless the ListItem is already touched (opened)
        if (shouldPreventDefault(currentListItemElement, level, !!hasSubItems)) {
          event.preventDefault();
        }

        handleMouseEnter(event);
        setHoverStatus(currentListItemElement);

        if (!hasHoverStatus(currentListItemElement, level)) {
          setHoverStatus(currentListItemElement);

          if (hasSubItems) {
            // don't trigger the click... it was only hovered
            event.preventDefault();
          }
        }
      } else {
        onMouseEnter(event);
      }
    },
    [handleMouseEnter, hasSubItems, isHamburgerNavigation, level, onMouseEnter, setHoverStatus],
  );

  const handleOpenNavWithKeyboard = useCallback(
    // as any because this will be removed in a few weeks
    (event: any) => {
      // suppress the delay immediately
      if (navDom.current) {
        navDom.current.setAttribute('data-hover-delayed', 'true');
      }

      setRenderNextLevel(true);

      if (!isHamburgerNavigation) {
        // treat the focus as if it is touched
        setIsNavFocused(true);
        setHoverStatus((event.currentTarget as HTMLElement).parentElement as HTMLLIElement);
      }
    },
    [isHamburgerNavigation, setHoverStatus, setIsNavFocused],
  );

  const renderLink = () => {
    const target = link?.substr(0, 4) !== 'http' ? '_self' : '_blank';

    return (
      <NavigationItemLink
        className={classnames(className, { [styles.highlightNavLink]: isHighlightLink })}
        hasSubItems={hasSubItems}
        isOverview={isOverview}
        level={level}
        link={link}
        name={name}
        isNavFocused={isNavFocused}
        onClick={handleClick}
        onFocus={handleFocus}
        handleMouseLeave={handleMouseLeave}
        handleOpenNavWithKeyboard={handleOpenNavWithKeyboard}
        onTouchEnd={handleTouchEnd}
        svg={svg}
        target={target}
      />
    );
  };

  const nextLevel = level + 1;

  return (
    <li
      data-level={level}
      data-purpose={`navigation.navitem.level${level}`}
      key={id}
      {...(isOverview ? { 'data-overview': true } : null)} // eslint-disable-line react/jsx-props-no-spreading
      className={classnames(styles.navitem, className, styles[`Level${level}`], {
        [styles.fullWidthLink]: layoutType === 'teaser' && level === 2,
        [styles.overviewMobile]: isOverview,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={listElement}
    >
      {renderLink()}
      {hasSubItems && (level === 0 || renderNextLevel) && (
        <NavigationList
          hasOnlyOneSubItem={hasOnlyOneSubItem}
          id={id}
          isNavFocused={isNavFocused}
          setIsNavFocused={setIsNavFocused}
          isHamburgerNavigation={isHamburgerNavigation}
          level={nextLevel}
          link={link}
          maxItemsShown={
            hasOnlyOneSubItem ? MAX_RENDERED_SUB_ITEMS_IF_SPACE_AVAILABLE : MAX_RENDERED_SUB_ITEMS
          }
          name={name}
          onClickItem={onClickItem}
          onClickLevelBack={onClickLevelBack}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onRemoveHoverStatusImmediately={onRemoveHoverStatusImmediately}
          parentItem={parentItem}
          renderAllSubLevels={renderNextLevel}
        />
      )}
    </li>
  );
};

export default track(tagComponent('NavigationItem'))(memo(NavigationItem));
