import { useEffect, useRef } from 'react';

/**
 * Tracks previous state of a value.
 *
 * @param value Props, state or any other calculated value.
 * @param refresh Attribute that defines if we will save the value or not yet
 * @returns Value from the previous render of the enclosing component.
 * */
const usePrevious = <Type,>(value: Type, refresh = true): Type | undefined => {
  const ref = useRef<Type>();
  useEffect(() => {
    if (refresh) {
      ref.current = value;
    }
  }, [value, ref, refresh]);
  return ref.current;
};

export default usePrevious;
