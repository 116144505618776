import { useCallback, useState, useEffect, useRef } from 'react';
import { throttling } from 'utils/function';

const useIndicator = () => {
  const [leftOffset, setLeftOffset] = useState(0);
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);
  const refParent = elementRef.current?.closest('nav > ul');

  const hideEvent = useCallback(() => {
    setWidth(0);
  }, []);

  const calculateValues = useCallback((element) => {
    elementRef.current = element;
    const { offsetLeft, offsetParent } = element;
    // get width of element
    const parentElement = element.closest('nav > ul');

    let itemOffsetLeft = parentElement?.scrollLeft
      ? offsetLeft - parentElement.scrollLeft
      : offsetLeft;

    if (!itemOffsetLeft && offsetParent instanceof Element) {
      itemOffsetLeft = offsetParent.offsetLeft;
    }
    const computedStyle = getComputedStyle(element, null);

    const computedWidth = parseFloat(computedStyle.getPropertyValue('width'));
    const paddingLeft = parseFloat(computedStyle.getPropertyValue('padding-left'));
    const paddingRight = parseFloat(computedStyle.getPropertyValue('padding-right'));

    const calculatedOffsetLeft =
      itemOffsetLeft + (computedWidth - (paddingLeft === 0 && paddingRight)) * 0.5;
    const calculatedWidth = computedWidth - paddingLeft - paddingRight;

    return { calculatedOffsetLeft, calculatedWidth };
  }, []);

  const showEvent = useCallback(
    (event) => {
      elementRef.current = event.target;

      const { calculatedOffsetLeft, calculatedWidth } = calculateValues(event.target.parentElement);
      setLeftOffset(calculatedOffsetLeft);
      setWidth(calculatedWidth);
    },
    [calculateValues],
  );

  const handleScrollChange = throttling(() => {
    if (refParent?.scrollLeft) {
      const { calculatedOffsetLeft, calculatedWidth } = calculateValues(elementRef.current);
      setLeftOffset(calculatedOffsetLeft);
      setWidth(calculatedWidth);
    }
  }, 100);

  useEffect(() => {
    refParent?.addEventListener('scroll', handleScrollChange);

    return () => {
      refParent?.removeEventListener('scroll', handleScrollChange);
    };
  }, [handleScrollChange, refParent]);

  return { showEvent, hideEvent, leftOffset, width };
};

export default useIndicator;
