import React from 'react';
import PropTypes from 'prop-types';
import { noop } from '@xxxlgroup/hydra-utils/common';

import KeywordItem from 'components/Header/components/Suggest/components/KeywordItems/components/KeywordItem';

const KeywordItems = ({ items, handleClick, handleHover, searchTerm, typeAhead }) => {
  if (!items) {
    return null;
  }

  return items.map((item) => (
    <KeywordItem
      key={item.id}
      item={item}
      handleClick={handleClick}
      handleHover={handleHover}
      searchTerm={searchTerm}
      typeAhead={typeAhead}
    />
  ));
};

KeywordItems.propTypes = {
  /** Handle click callback */
  handleClick: PropTypes.func,
  /** Handle hover callback */
  handleHover: PropTypes.func,
  /** Items to display */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  /** Search term to highlight  */
  searchTerm: PropTypes.string,
  /** Type ahead callback */
  typeAhead: PropTypes.func,
};

KeywordItems.defaultProps = {
  handleClick: noop,
  handleHover: noop,
  items: null,
  searchTerm: null,
  typeAhead: noop,
};

export default KeywordItems;
