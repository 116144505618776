import React, { FocusEvent, PointerEvent, MouseEvent, useCallback } from 'react';
import classnames from 'classnames';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon/pseudoIcon';
import { arrowLeft } from '@xxxlgroup/hydra-icons';
import { useTracking } from 'utils/tracking/hooks';
import Message from 'components/Message';
import { BackButtonProps } from 'components/Header/components/Navigation/Navigation.types';

import styles from 'components/Header/components/Navigation/components/BackButton/BackButton.scss';

const BackButton = (props: BackButtonProps) => {
  const { onClick, level } = props;

  const tracking = useTracking(props, 'NavigationList');

  const handleClick = useCallback(
    (event: PointerEvent<HTMLButtonElement>) => {
      tracking(event);
      onClick(event, level);
    },
    [tracking, onClick, level],
  );

  const handleHover = useCallback(
    (event: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>) => {
      tracking(event);
    },
    [tracking],
  );

  const [backIconStyle, backIconClassName] = pseudoIcon(arrowLeft);

  return (
    <li
      key="backButtonWrapper"
      data-backbutton
      className={styles.backButtonWrapper}
      data-level={level}
    >
      <button
        data-purpose={`navigation.navitem.level${level}.button.levelBack`}
        type="button"
        onClick={handleClick}
        onMouseOver={handleHover}
        onFocus={handleHover}
        className={classnames(styles.backButton, backIconClassName)}
        style={backIconStyle}
      >
        <Message code="wxs.navigation.back.text" />
      </button>
    </li>
  );
};

export default BackButton;
