import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@xxxlgroup/hydra-ui-components';

import styles from 'components/Header/components/Suggest/components/SuggestList/SuggestList.scss';

const SuggestList = ({ heading, 'data-purpose': dataPurpose, children }) =>
  children && (
    <div className={styles.area} data-purpose={dataPurpose}>
      <Heading level={3} data-purpose="header.suggest.headline" content={heading} />
      <ul className={styles.links}>{children}</ul>
    </div>
  );

SuggestList.propTypes = {
  /** Children items */
  children: PropTypes.node,
  /** Data-purpose attribute of section  */
  'data-purpose': PropTypes.string.isRequired,
  /** Heading text to display before items */
  heading: PropTypes.string.isRequired,
};

SuggestList.defaultProps = {
  children: null,
};

export default SuggestList;
