import React, { memo, useMemo } from 'react';
import classnames from 'classnames';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon/pseudoIcon';
import * as icons from '@xxxlgroup/hydra-icons';
import { useLocation } from 'react-router-dom';
import useMessage from 'components/Message/useMessage';
import { NavigationItemLinkProps } from 'components/Header/components/Navigation/Navigation.types';

import Link from 'components/WebshopLink';
import { LEVELS_WITH_ICONS_ALLOWED } from 'components/Header/components/Navigation/Navigation.config';
import useLanguage from 'hooks/useLanguage';

import styles from 'components/Header/components/Navigation/components/NavigationItem/NavigationItem.scss';
import stylesNav from 'components/Header/components/Navigation/components/NavigationItemLink/NavigationItemLink.scss';
import useMediaQuery from 'components/MediaQuery/useMediaQuery';
import { Breakpoint } from 'components/MediaQuery/MediaQuery.types';

const getDataPurpose = (isOverview: boolean) =>
  isOverview ? 'navigation.navitem.link.allProducts' : 'navigation.navitem.link';

const MOEMAX = 'moemax';
const XXXLUTZ = 'xxxlutz';
const SALE_LINK = '% Sale %';
const ANGEBOTE_LINK = 'Angebote';

const NavigationItemLink = ({
  className,
  hasSubItems,
  isOverview,
  level = 0,
  link,
  handleMouseLeave = noop,
  handleOpenNavWithKeyboard = noop,
  name,
  onClick = noop,
  onFocus = noop,
  onTouchEnd = noop,
  svg,
  target,
}: NavigationItemLinkProps) => {
  const isMobileLayout = useMediaQuery({ smallerThan: Breakpoint.lg });
  const icon = useMemo(() => {
    if (!LEVELS_WITH_ICONS_ALLOWED.includes(level) || !svg || isOverview) {
      return null;
    }
    return Object.keys(icons).includes(svg) ? icons[svg as keyof typeof icons] : null;
  }, [level, svg, isOverview]);

  const { pathname } = useLocation();
  const ariaCurrentPage = pathname === link ? 'page' : null;

  const [iconStyle, iconClassName] = (icon && pseudoIcon(icon)) || [null, null];
  const [arrowStyle, arrowClassName] = (hasSubItems && pseudoIcon(icons.chevronDown, 'after')) || [
    null,
    null,
  ];

  const language = useLanguage();
  const isNameToHighlight =
    (language.rail === MOEMAX && name === SALE_LINK) ||
    (language.rail === XXXLUTZ && name === ANGEBOTE_LINK);

  const [navigationOverviewText, openSubmenuFor] = useMessage(
    ['wxs.navigation.overview.text', 'wxs.navigation.itemlink.button.arialabel'],
    {
      itemName: name ?? '',
    },
  );

  return (
    <>
      <Link
        aria-current={ariaCurrentPage}
        aria-haspopup={hasSubItems}
        className={classnames(
          styles.link,
          styles[`Level${level}`],
          className,
          iconClassName,
          arrowClassName,
          {
            [styles.withoutIcon]: !iconStyle,
            [styles.overview]: isOverview,
            [styles.highlightNavLink]: isNameToHighlight,
          },
        )}
        data-purpose={getDataPurpose(!!isOverview)}
        href={link}
        onClick={onClick}
        onFocus={level === 1 ? onFocus : handleMouseLeave}
        onTouchEnd={onTouchEnd}
        style={{ ...iconStyle, ...arrowStyle }}
        target={target}
      >
        {isOverview ? navigationOverviewText : name}
      </Link>
      {!isMobileLayout && (level === 0 || level === 1) && (
        <button
          aria-label={openSubmenuFor}
          onClick={handleOpenNavWithKeyboard}
          onFocus={level === 1 ? onFocus : noop}
          className={stylesNav.arrowButton}
        >
          <i className={classnames(arrowClassName, stylesNav.arrow)} style={{ ...arrowStyle }} />
        </button>
      )}
    </>
  );
};

export default memo(NavigationItemLink);
