import React, { FC } from 'react';
import { Icon } from '@xxxlgroup/hydra-ui-components';
import { x } from '@xxxlgroup/hydra-icons';

interface DeleteSearchTermButtonProps {
  /** function to clear the search term */
  handleClearValue: () => void;
  /** style object for the delete button */
  styles: Record<string, string>;
}

const DeleteSearchTermButton: FC<DeleteSearchTermButtonProps> = ({ handleClearValue, styles }) => (
  <button
    className={styles.closeButton}
    aria-label="cancel"
    data-testid="cancel.button"
    type="reset"
    onMouseDown={handleClearValue}
    tabIndex={0}
  >
    <Icon
      // no possibility to replace it with pseudo icon due to styling issues
      glyph={x}
      className={styles.closeButtonIcon}
    />
  </button>
);

export default DeleteSearchTermButton;
