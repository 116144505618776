import React, { FC, MouseEventHandler } from 'react';
import classnames from 'classnames';

import { Button, IconButton } from '@xxxlgroup/hydra-ui-components';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { cameraDSLR, x } from '@xxxlgroup/hydra-icons';
import { local } from '@xxxlgroup/hydra-utils/storage';
import track from 'react-tracking';

import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import useMessage from 'components/Message/useMessage';
import {
  IMAGE_SEARCH_BUTTON_CLICKED,
  IMAGE_SEARCH_TOOLTIP_DISPLAYED,
} from 'components/Header/components/ImageSearchButton/ImageSearchButton';
import { useImageSearchContext } from 'pages/ImageSearch/ImageSearch.provider';
import { actions } from 'pages/ImageSearch/ImageSearch.types';
import { SEARCH_BAR_IMAGE_INTERACTION_PURPOSE } from 'pages/ImageSearch/ImageSearch.state';

import styles from 'components/Header/components/SearchBar/components/PromoteToolTip/PromoteToolTip.scss';

interface PromoteToolTipProps {
  closeTooltip: () => void;
  handleHover: MouseEventHandler<HTMLDivElement>;
  isTooltipFromFocus: boolean;
}

const PromoteToolTip: FC<PromoteToolTipProps> = (props) => {
  const { closeTooltip, handleHover, isTooltipFromFocus } = props;

  const tracking = useTracking(props, 'PromoteToolTip');
  const { dispatch } = useImageSearchContext();

  const [iconStyle, iconClassName] = pseudoIcon(cameraDSLR);
  const [buttonText, contentText, titleText, closeAriaLabel] = useMessage([
    'imagesearch.tooltip.button',
    'imagesearch.tooltip.content',
    'imagesearch.tooltip.title',
    'wxs.modal.closeButton.ariaLabel',
  ]);

  const handleClose = (event: React.MouseEvent) => {
    closeTooltip();
    tracking(event, {
      type: 'imageSearch',
      event: { type: 'click', purpose: SEARCH_BAR_IMAGE_INTERACTION_PURPOSE },
      props: { action: 'Active Tooltip::Close' },
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    dispatch({ type: actions.SHOW_MODAL });
    closeTooltip();

    local.setItem(IMAGE_SEARCH_BUTTON_CLICKED, true);
    local.setItem(IMAGE_SEARCH_TOOLTIP_DISPLAYED, true);

    tracking(event, {
      type: 'imageSearch',
      event: { type: 'click', purpose: SEARCH_BAR_IMAGE_INTERACTION_PURPOSE },
      props: { action: 'Active-Tooltip::Test now' },
    });
  };

  return (
    <div
      className={classnames(styles.tooltip, { [styles.animate]: isTooltipFromFocus })}
      data-purpose="imageSearch.tooltip"
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      {isTooltipFromFocus && (
        <IconButton
          ariaLabel={closeAriaLabel}
          className={styles.closeButton}
          data-purpose="imageSearch.tooltip.close"
          glyph={x}
          onClick={handleClose}
        />
      )}

      <div className={styles.bottomContent}>
        <div className={classnames(styles.title, iconClassName)} style={iconStyle}>
          <span className={styles.titleText}>{titleText}</span>
        </div>

        <p
          className={classnames(styles.content, {
            [styles.hoverContent]: !isTooltipFromFocus,
          })}
        >
          {contentText}
        </p>

        {isTooltipFromFocus && (
          <Button
            className={styles.button}
            data-purpose="imageSearch.tooltip.openImageSearch"
            inverted
            onClick={handleClick}
            variant="secondary"
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default track(tagComponent('PromoteToolTip'))(PromoteToolTip);
