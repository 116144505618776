import React, { ComponentType, useEffect, useRef, useState } from 'react';
import track from 'react-tracking';
import classnames from 'classnames';
import { config } from '@xxxlgroup/hydra-config';
import { Card, CardOverlay, CardImage } from '@xxxlgroup/hydra-ui-components';
import withLinkModifier from '@xxxlgroup/hydra-ui-components/dist/common/molecules/Card/hoc/withLinkModifier';
import { tagComponent } from 'utils/tracking/tracking';
import { useImpressionTracker } from 'utils/tracking/hooks';
import Link from 'components/WebshopLink';
import {
  NavigationCardProps,
  NavigationCardSize,
} from 'components/Header/components/Navigation/Navigation.types';
import { traverseAncestors } from 'components/Header/components/Navigation/components/NavigationCard/traverseAncestors';
import styles from 'components/Header/components/Navigation/components/NavigationCard/NavigationCard.scss';

const TRACKING_PROPS = {
  event: 'impression',
  type: 'NavigationCard',
  purpose: 'navigation.teaser.flyout',
};

const NavigationCard = (props: NavigationCardProps) => {
  const { data, onClick, size = NavigationCardSize.FULL } = props;
  const { cmsComponentName, className, link, image, target, title, variant, id } = data;
  const cardRef = useRef<HTMLDivElement>(null);
  const [parentCategories, setParentCategories] = useState<string[]>([]);

  useEffect(() => {
    const htmlNode = cardRef.current;
    const parentCategories: string[] = [];
    htmlNode &&
      traverseAncestors(htmlNode, (ancestor: HTMLElement) => {
        parentCategories.unshift(ancestor.innerText);
      });

    setParentCategories(parentCategories);
  }, []);

  const { setRef } = useImpressionTracker(
    {
      name: cmsComponentName,
      ...props,
      ...data,
      creative: 'cmsTextImageComponent',
      id,
      position: parentCategories.join('::'),
    },
    'useImpressionTracker(NavigationCard)',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    TRACKING_PROPS,
  );

  const renderOverlay = () =>
    title ? (
      <CardOverlay key="overlay" variant={variant} hasLink={!!link}>
        {title}
      </CardOverlay>
    ) : undefined;

  const imageSizes = {
    FULL: [
      `(max-width: ${config.breakpoints.md}px) calc(100vw - 3rem)`,
      `(max-width: ${config.breakpoints.lg}px) calc(100vw - 1.5rem)`,
      `(max-width: 1600px) calc(100vw - 17rem - 1.5rem)`,
      `(max-width: ${config.breakpoints.xxl}px) 640px`,
      `584px`,
    ],
    HALF: [
      `(max-width: ${config.breakpoints.md}px) calc(100vw - 3rem)`,
      `(max-width: ${config.breakpoints.lg}px) calc(50vw - 1.5rem)`,
      `(max-width: 1600px) calc((100vw - 17rem) / 2)`,
      `(max-width: ${config.breakpoints.xxl}px) 640px`,
      `584px`,
    ],
    THIRD: [
      `(max-width: ${config.breakpoints.md}px) calc(100vw - 3rem)`,
      `(max-width: ${config.breakpoints.lg}px) calc(33vw - 1.5rem)`,
      `(max-width: 1600px) calc((100vw - 17rem) / 3)`,
      `(max-width: ${config.breakpoints.xxl}px) 420px`,
      `376px`,
    ],
    SPECIAL: [
      `(max-width: ${config.breakpoints.lg}px) calc(100vw - 3rem)`,
      `(max-width: ${config.breakpoints.xl}px) calc(100vw - 17rem - 1.5rem)`,
      `17rem`,
    ],
  };

  return (
    <div ref={cardRef}>
      <div ref={setRef}>
        <Card
          variant="image"
          target={target}
          data-purpose="teaserCard.card"
          className={classnames(className, styles.customTeaserCardDimensions)}
          overlay={renderOverlay()}
          onClick={onClick}
          renderLink={
            link &&
            withLinkModifier(Link as ComponentType)({
              ...TRACKING_PROPS,
              'data-purpose': 'navigation.teaser.flyout',
              href: link,
              isPromotion: data?.isPromotion,
            })
          }
        >
          {image && (
            <CardImage isBackground source={image} imageSizes={imageSizes[size].join(',')} />
          )}
        </Card>
      </div>
    </div>
  );
};

export default track(tagComponent('NavigationCard'))(NavigationCard);
