import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/Header/components/Indicator/Indicator.scss';

const Indicator = ({ leftOffset, width }) => (
  <div
    className={styles.indicatorWrapper}
    data-purpose="header.indicator"
    style={{ left: `${leftOffset}px` }}
  >
    <div className={styles.indicator} style={{ width: `${width}px` }} />
  </div>
);

Indicator.propTypes = {
  /** number of pixels of the left position of the indicator */
  leftOffset: PropTypes.number,
  /** number of pixels of the width of the indicator */
  width: PropTypes.number,
};

Indicator.defaultProps = {
  leftOffset: 0,
  width: 0,
};

export default Indicator;
