import React from 'react';
import { Content } from '@xxxlgroup/hydra-ui-components';
import { escapeRegExp } from '@xxxlgroup/hydra-utils/formatting';

import styles from 'components/Header/components/Suggest/Suggest.scss';

export const highlightSearch = (text: string, searchTerm: string) => {
  const content = searchTerm
    ? text.replace(
        new RegExp(`(${escapeRegExp(searchTerm)})`, 'gi'),
        `<span class=${styles.highlight}>$1</span>`,
      )
    : text;

  return <Content content={content} tag="span" />;
};
