import React, { useMemo, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { Button, Input } from '@xxxlgroup/hydra-ui-components';
import IconButton from '@xxxlgroup/hydra-ui-components/dist/common/components/IconButton/IconButton';
import { search } from '@xxxlgroup/hydra-icons';
import track from 'react-tracking';

import { tagComponent } from 'utils/tracking/tracking';
import useMediaQuery from 'components/MediaQuery/useMediaQuery';
import useMessage from 'components/Message/useMessage';
import Suggest from 'components/Header/components/Suggest';
import ErrorBoundary from 'components/ErrorBoundary';
import useSearchBarController from 'components/Header/components/SearchBar/useSearchBarController';
import DeleteSearchTermButton from 'components/Header/components/SearchBar/components/DeleteSearchTermButton/DeleteSearchTermButton';
import ImageSearchButton from 'components/Header/components/ImageSearchButton/ImageSearchButton';
import SpeechToTextButton from 'components/Header/components/SpeechToTextButton/SpeechToTextButton';
import PromoteToolTip from 'components/Header/components/SearchBar/components/PromoteToolTip';

import styles from 'components/Header/components/SearchBar/SearchBar.scss';

const SearchBar = (props) => {
  const { className, closeOverlay, isBurgerActive, isOverlayOpen } = props;

  const [iconStyle, iconClassName] = pseudoIcon(search);
  const inputWrapperRef = useRef(null);
  const formRef = useRef(null);

  const {
    callbacks: {
      closeTooltip,
      handleBlur,
      handleChange,
      handleClearValue,
      handleClick,
      handleCloseOverlay,
      handleDebounceTerm,
      handleFocus,
      handleHover,
      handleImageSearchHover,
      handleSubmit,
      handleSearchButtonClick,
      setIsSuggestEmpty,
      setIsVoiceSearch,
      setSearchTerm,
    },
    flags: {
      isImageSearchFeatureEnabled: isImageSearchEnabled,
      isImageSearchTooltipEnabled,
      isVoiceSearchEnabled,
    },
    states: { debounceTerm, isFocused, isTooltipFromHover, isTooltipFromFocus, searchTerm },
  } = useSearchBarController({
    formRef,
    inputWrapperRef,
    props,
  });

  const isTouch = useMediaQuery({ touchOnly: true });

  const [inputLabel, buttonLabel, cancel, placeholder] = useMessage(
    [
      'wxs.header.searchBar.ariaLabel',
      'wxs.header.searchBar.button.ariaLabel',
      'wxs.header.searchBar.cancel',
      'wxs.header.searchBar.placeholder',
    ],
    null,
    true,
  );

  const isAlternativeSearchEnabled = isImageSearchEnabled || isVoiceSearchEnabled;
  const isTooltipEnabled = isImageSearchEnabled && isImageSearchTooltipEnabled;
  const shouldShowToolTip = isTooltipEnabled && (isTooltipFromHover || isTooltipFromFocus);
  const i18n = useMemo(() => ({ cancel }), [cancel]);
  const SuffixButtons = useMemo(
    () =>
      isAlternativeSearchEnabled ? (
        <div className={styles.searchIconButtons}>
          <DeleteSearchTermButton styles={styles} handleClearValue={handleClearValue} />
          {isVoiceSearchEnabled && (
            <SpeechToTextButton
              setTranscribedText={setSearchTerm}
              handleSubmit={handleSubmit}
              closeOverlay={closeOverlay}
              setIsVoiceSearch={setIsVoiceSearch}
            />
          )}
          {isImageSearchEnabled && (
            <ImageSearchButton
              closeTooltip={closeTooltip}
              handleHover={handleImageSearchHover}
              withModal
            />
          )}
        </div>
      ) : (
        <span className={classnames(styles.inputSuffix, iconClassName)} style={iconStyle} />
      ),
    [
      closeOverlay,
      closeTooltip,
      handleClearValue,
      handleImageSearchHover,
      handleSubmit,
      iconClassName,
      iconStyle,
      isAlternativeSearchEnabled,
      isImageSearchEnabled,
      setIsVoiceSearch,
      setSearchTerm,
      isVoiceSearchEnabled,
    ],
  );

  return (
    <form
      data-purpose="header.searchBar"
      className={classnames(className, styles.searchBar, {
        [styles.suggestOpen]: isOverlayOpen,
        [styles.focused]: isFocused,
        [styles.hideSearchBar]: isBurgerActive,
      })}
      onSubmit={handleSubmit(searchTerm)}
      ref={formRef}
      role="search"
    >
      <Input
        aria-label={inputLabel}
        autoComplete="off"
        className={classnames(styles.input, {
          [styles.suggestOpen]: isOverlayOpen,
          [styles.inputWithActionIcons]: isAlternativeSearchEnabled,
          [styles.searchEmpty]: searchTerm === '',
        })}
        data-purpose="header.searchBar.input"
        data-track-id="searchBox"
        enterKeyHint="search"
        forwardedRef={inputWrapperRef}
        hideCancelButton={isAlternativeSearchEnabled || !searchTerm}
        hideLabel
        i18n={i18n}
        inputMode="search"
        isTouch={isTouch}
        keepCancelButton={!isAlternativeSearchEnabled}
        label={inputLabel}
        name="search"
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={handleClick}
        onFocus={handleFocus}
        placeholder={placeholder}
        prefix={
          isAlternativeSearchEnabled ? (
            <IconButton
              ariaLabel={buttonLabel}
              className={styles.prefixedSubmitButton}
              data-purpose="header.searchBar.button.submit"
              data-track-id="searchButton"
              glyph={search}
              onClick={closeOverlay}
              onFocus={handleHover}
              onMouseEnter={handleHover}
              type="submit"
            />
          ) : null
        }
        suffix={SuffixButtons}
        type="search"
        value={searchTerm}
      />
      {isOverlayOpen && (
        <ErrorBoundary>
          <Suggest
            className={classnames({
              [styles.suggestWithActionIcons]: isAlternativeSearchEnabled,
            })}
            inputWrapperRef={inputWrapperRef}
            term={debounceTerm}
            updateDebounceTerm={handleDebounceTerm}
            onClickLink={handleCloseOverlay}
            setIsSuggestEmpty={setIsSuggestEmpty}
          />
        </ErrorBoundary>
      )}

      {!isAlternativeSearchEnabled && (
        <Button
          ariaLabel={buttonLabel}
          className={styles.button}
          data-purpose="header.searchBar.button.submit"
          data-track-id="searchButton"
          glyphBefore={search}
          onClick={handleSearchButtonClick}
          onFocus={handleHover}
          onMouseEnter={handleHover}
          type="submit"
        />
      )}

      {shouldShowToolTip && (
        <PromoteToolTip
          closeTooltip={closeTooltip}
          handleHover={handleImageSearchHover}
          isTooltipFromFocus={isTooltipFromFocus}
        />
      )}
    </form>
  );
};

SearchBar.propTypes = {
  /** @ignore */
  className: PropTypes.string,
  /** callback function to close hamburger menu */
  /* eslint-disable-next-line react/no-unused-prop-types */
  closeHamburger: PropTypes.func,
  /** callback function for closing overlay in header */
  closeOverlay: PropTypes.func,
  /** Whether the navigation should be displayed (mobile only). */
  isBurgerActive: PropTypes.bool,
  /** Boolean: if Search in mobile layout or not */
  /* eslint-disable-next-line react/no-unused-prop-types */
  isMobileLayout: PropTypes.bool,
  /** callback function for opening overlay in header */
  /* eslint-disable-next-line react/no-unused-prop-types */
  openOverlay: PropTypes.func,
  /** is overlay open */
  /* eslint-disable-next-line react/no-unused-prop-types */
  isOverlayOpen: PropTypes.bool,
};

SearchBar.defaultProps = {
  className: null,
  closeOverlay: noop,
  closeHamburger: noop,
  isBurgerActive: false,
  isMobileLayout: false,
  openOverlay: noop,
  isOverlayOpen: false,
};

export default track(tagComponent('SearchBar'))(SearchBar);
