import React, { useRef, useEffect, useCallback } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { noop } from '@xxxlgroup/hydra-utils/common';
import classnames from 'classnames';
import track from 'react-tracking';

import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import usePrevious from 'hooks/usePrevious';
import preloadSubItems from 'components/Header/components/Navigation/utils/preloadSubItems';

import NavigationList from 'components/Header/components/Navigation/components/NavigationList';
import { resetNavigationClasses } from 'components/Header/components/Navigation/Navigation.Dom';
import useNavigationHooks from 'components/Header/components/Navigation/hooks/useNavigationHooks';

import { NavigationProps } from 'components/Header/components/Navigation/Navigation.types';

import styles from 'components/Header/components/Navigation/Navigation.scss';

const Navigation = (props: NavigationProps) => {
  const {
    className,
    isBurgerActive,
    isHamburgerNavigation,
    onCloseHamburger = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
    setIsNavFocused,
    isNavFocused = false,
  } = props;

  const isHamburgerNavigationRef = useRef(isHamburgerNavigation);
  useEffect(() => {
    isHamburgerNavigationRef.current = isHamburgerNavigation;
  }, [isHamburgerNavigation]);

  const client = useApolloClient();

  // Call the function when you want to set the focus
  useEffect(() => {
    preloadSubItems(client, 'header_nav_root', 0, isHamburgerNavigation);
  }, [client, isHamburgerNavigation]);

  const navRef = useRef<HTMLElement | null>(null);

  const keyListenerAdded = useRef(false);
  const tracking = useTracking(props, 'Navigation');
  const { pathname } = useLocation();

  const allowHover = useCallback(() => {
    navRef.current?.setAttribute('data-hover-delayed', 'true');

    if (!isHamburgerNavigation && navRef.current) {
      tracking(Navigation);
    }
  }, [isHamburgerNavigation, navRef, tracking]);

  const {
    closeDesktopNavigation,
    handleClickItem,
    handleKeyDown,
    handleMobileMenuFocus,
    handleMouseEnter,
    handleMouseLeave,
    handleRemoveHoverStatusImmediately,
    onClickLevelBack,
  } = useNavigationHooks({
    allowHover,
    isHamburgerNavigation,
    isHamburgerNavigationRef,
    navRef,
    onCloseHamburger,
    onMouseEnter,
    onMouseLeave,
    setIsNavFocused,
  });

  const prevBurgerActive = usePrevious(isBurgerActive);
  const isPrevHamburgerNavigation = usePrevious(isHamburgerNavigation);
  const prevPathname = usePrevious(pathname);

  useEffect(() => {
    // we're not able to use reacts event handling because preventDefault doesn't work as expected.
    // See https://medium.com/@ericclemmons/react-event-preventdefault-78c28c950e46
    if (!keyListenerAdded.current && navRef.current) {
      navRef.current.addEventListener('keydown', handleKeyDown);
      document.addEventListener('keydown', closeDesktopNavigation);
      keyListenerAdded.current = true;
    }

    const navCleaned = navRef.current;

    return () => {
      navCleaned?.addEventListener('keydown', handleKeyDown);
      document.removeEventListener('keydown', closeDesktopNavigation);
      keyListenerAdded.current = false;
    };
  }, [closeDesktopNavigation, handleRemoveHoverStatusImmediately, handleKeyDown, navRef]);

  useEffect(() => {
    // Remove all manual DOM manipulations when the navigation closes. Otherwise, ReactJS won't work properly anymore.
    // Although it only effects the hamburger menu, we need to do this on desktop too due to a possible viewport change.
    if (isBurgerActive !== prevBurgerActive) {
      navRef.current?.classList && resetNavigationClasses(navRef.current);
    }
  }, [isBurgerActive, prevBurgerActive]);

  useEffect(() => {
    // trigger closing the hamburger menu to prevent unexpected behaviour
    if (isPrevHamburgerNavigation !== isHamburgerNavigation && isBurgerActive) {
      onCloseHamburger();
    }
  }, [isPrevHamburgerNavigation, isHamburgerNavigation, isBurgerActive, onCloseHamburger]);

  useEffect(() => {
    // trigger closing the hamburger menu when the route changes
    if (isBurgerActive && prevPathname !== pathname) {
      onCloseHamburger();
    }
  }, [isBurgerActive, prevPathname, pathname, onCloseHamburger]);

  useEffect(() => {
    // Add listner that optimizes the focus for keyboard navigation when the hamburger menu is active
    if (isBurgerActive) {
      document.addEventListener('keydown', handleMobileMenuFocus);
    }

    return () => {
      document.removeEventListener('keydown', handleMobileMenuFocus);
    };
  }, [handleMobileMenuFocus, isBurgerActive]);

  const usedClasses = classnames(styles.navigation, styles['current-0'], className, {
    [styles.burgerActive]: isBurgerActive,
  });

  return (
    <nav className={usedClasses} data-purpose="navigation.container" ref={navRef}>
      <NavigationList
        id="mainNavigation-current-0"
        isHamburgerNavigation={isHamburgerNavigation}
        isScrollable
        isNavFocused={isNavFocused}
        setIsNavFocused={setIsNavFocused}
        onClickItem={handleClickItem}
        onClickLevelBack={onClickLevelBack}
        onCloseHamburger={onCloseHamburger}
        onMouseEnter={handleMouseEnter('mainNavigation-current-0')}
        onMouseLeave={handleMouseLeave}
        onRemoveHoverStatusImmediately={handleRemoveHoverStatusImmediately}
      />
    </nav>
  );
};

export default track(tagComponent('Navigation'))(withRouter(Navigation)) as any;
