import React, { useCallback, useEffect, useMemo, useRef, useState, memo } from 'react';
import classnames from 'classnames';
import track from 'react-tracking';
import { useQuery } from '@apollo/client';
import { isArrayEmpty, noop } from '@xxxlgroup/hydra-utils/common';
import { config } from '@xxxlgroup/hydra-config';
import { IconButton, Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import { Glyph } from '@xxxlgroup/hydra-ui-components/dist/common/types/typeDefinitions';
import { chevronLeft, chevronRight, location } from '@xxxlgroup/hydra-icons';
import { useGetMetaNavigationQuery } from 'graphql-types/generated/types-generated';
import { tagComponent } from 'utils/tracking/tracking';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import useIsMountedOnCSR from 'hooks/useIsMountedOnCsr';
import Message from 'components/Message';
import useMessage from 'components/Message/useMessage';
import BackButton from 'components/Header/components/Navigation/components/BackButton';
import NavigationItem from 'components/Header/components/Navigation/components/NavigationItem';
import NavigationCard from 'components/Header/components/Navigation/components/NavigationCard';
import MyAccountTeaser from 'components/Header/components/Navigation/components/MyAccountTeaser';
import Link from 'components/WebshopLink';
import { NAVIGATION_QUERY } from 'components/Header/components/Navigation/Navigation.query';
import {
  InteractiveUIEventTypes,
  LayoutTypes,
  NavigationCardSize,
  NavigationListProps,
  NaviItem,
} from 'components/Header/components/Navigation/Navigation.types';
import {
  LEVELS_WITH_SHOW_ALL_BUTTON,
  MAX_NESTED_LEVELS,
} from 'components/Header/components/Navigation/Navigation.config';
import ErrorHandler from 'components/ErrorHandler';
import ContrastToggle from 'components/ContrastToggle';

import styles from 'components/Header/components/Navigation/components/NavigationList/NavigationList.scss';

const NavigationList = React.memo((props: NavigationListProps) => {
  const {
    hasOnlyOneSubItem,
    id,
    isNavFocused,
    setIsNavFocused,
    isHamburgerNavigation,
    isScrollable = false,
    level = 0,
    link,
    maxItemsShown = 5,
    name,
    onClickItem = noop,
    onClickLevelBack = noop,
    onCloseHamburger = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
    onRemoveHoverStatusImmediately = noop,
    parentItem,
    renderAllSubLevels,
  } = props;

  const navRef = useRef<HTMLUListElement>(null);
  const [isNextActive, setIsNextActive] = useState(true);
  const [shouldShowScroll, setShouldShowScroll] = useState(false);
  const isMetaNavigationEnabled = useFeatureFlagsEnabled('poseidon.nav.metaNavigation.enabled');
  const [storeFinderLinkText, storeFinderLink] = useMessage(
    ['storefinder.link', 'storefinder.link.url'],
    {},
    true,
  );
  const { data: navigationData, error } = useQuery(NAVIGATION_QUERY, {
    fetchPolicy: level === 0 && CONFIG.IS_SSR ? 'cache-and-network' : 'cache-only',
    variables: {
      codes: [parentItem?.code || 'header_nav_root'],
      levelAmount: 1,
      levelOffset: level,
    },
  });
  const { data: metaNavigationData } = useGetMetaNavigationQuery();

  const isMountedOnCSR = useIsMountedOnCSR();

  const navItems = useMemo(() => navigationData?.navigation?.data?.main || [], [navigationData]);
  const navItemsCount = navItems.length;
  const metaNavItems = useMemo(
    () => metaNavigationData?.getMetaNavigation?.serviceNodes || [],
    [metaNavigationData],
  );

  const layoutType = useMemo(() => {
    if (parentItem?.layoutType && parentItem.layoutType !== 'standard') {
      return parentItem.layoutType;
    }
    if (
      navItems?.length > 1 &&
      ['cmslinkcomponent', 'cmscategorynavigationcomponent'].includes(navItems[0].type) &&
      navItems[1].type === 'cmstextimagecomponent'
    ) {
      return 'teaser';
    }
    return 'standard';
  }, [navItems, parentItem?.layoutType]);

  const hasShowAllBtn = useCallback(() => {
    if (isHamburgerNavigation && layoutType === 'teaser') {
      return false;
    }
    return LEVELS_WITH_SHOW_ALL_BUTTON.includes(level) && navItemsCount >= maxItemsShown;
  }, [isHamburgerNavigation, layoutType, level, maxItemsShown, navItemsCount]);

  const renderShowAllButton = useMemo(
    () => (
      <Message code={['wxs.navigation.link.showAll']}>
        {([showLink]) => (
          <NavigationItem
            className={classnames(styles.showAll, {
              [styles.showAllOneContainer]: hasOnlyOneSubItem,
            })}
            id={`${id}Id`}
            isHamburgerNavigation={isHamburgerNavigation}
            key={`${name}Link`}
            level={level}
            link={link}
            name={showLink}
            onClickItem={onClickItem}
            onRemoveHoverStatusImmediately={onRemoveHoverStatusImmediately}
          />
        )}
      </Message>
    ),
    [
      hasOnlyOneSubItem,
      id,
      isHamburgerNavigation,
      level,
      link,
      name,
      onClickItem,
      onRemoveHoverStatusImmediately,
    ],
  );

  const handleCardClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      onClickItem(event, false, level);
      // Note: Very ugly code below, needs to be addressed, but different types coming from Hydra & is in Poseidon
      onRemoveHoverStatusImmediately(event as unknown as InteractiveUIEventTypes);
    },
    [level, onClickItem, onRemoveHoverStatusImmediately],
  );

  const handleMouseLeave = useCallback(
    (event: InteractiveUIEventTypes) => {
      if (level === 0) {
        onMouseLeave(event);
      }
    },
    [level, onMouseLeave],
  );

  const handleMouseEnter = useCallback(
    (itemId?: string) => (event: InteractiveUIEventTypes) => {
      if (level === 0) {
        onMouseEnter(event, itemId);
      }
    },
    [level, onMouseEnter],
  );

  const shouldRenderItem = useCallback(
    (index: number, layoutMode?: LayoutTypes) => {
      if (layoutMode === 'teaser') {
        return true;
      }
      return (
        (isHamburgerNavigation && !hasOnlyOneSubItem) ||
        (isHamburgerNavigation && hasOnlyOneSubItem && index < maxItemsShown) ||
        !LEVELS_WITH_SHOW_ALL_BUTTON.includes(level) ||
        index < maxItemsShown - (hasShowAllBtn() ? 1 : 0)
      );
    },
    [hasOnlyOneSubItem, hasShowAllBtn, isHamburgerNavigation, level, maxItemsShown],
  );

  const navigationListClasses = classnames(styles.navlist, styles[`Level${level}`], {
    [styles.fullWidthList]: layoutType === 'teaser' && level === 2,
    [styles.hasOnlyOneSubItem]: level === 3 && hasOnlyOneSubItem,
    [styles.teaserCardsList]: layoutType === 'teaser',
  });

  // check how many other teaser are there to calculate the right image sizes attribute
  const cardSize = useMemo(() => {
    if (layoutType === 'teaser' && level === 2) {
      const cardAmount = navItems.filter(
        (item: NaviItem) => item.type === 'cmstextimagecomponent',
      ).length;

      if (cardAmount > 1) {
        return cardAmount === 2 ? NavigationCardSize.HALF : NavigationCardSize.THIRD;
      }
    }

    return NavigationCardSize.FULL;
  }, [layoutType, level, navItems]);

  const renderNavItems = useMemo(
    () =>
      navItems
        .filter((item: NaviItem) => !!item)
        .map((item: NaviItem, index: number) => {
          if (layoutType === 'teaser' && level === 2 && item.type === 'cmstextimagecomponent') {
            return (
              <li
                className={classnames(styles.teaserCardLayout, navItemsCount >= 5 && styles.large)}
                key={item.id}
              >
                <NavigationCard data={item} onClick={handleCardClick} size={cardSize} />
              </li>
            );
          }

          if (item.type === 'cmstextimagecomponent') {
            return (
              <li className={styles.teaserCardWrapper} data-level={level} key={item.id}>
                <NavigationCard
                  data={item}
                  onClick={handleCardClick}
                  size={NavigationCardSize.SPECIAL}
                />
              </li>
            );
          }

          if (shouldRenderItem(index, layoutType)) {
            const hasSubItems = level >= MAX_NESTED_LEVELS ? false : item.hasSubItems;

            return (
              <NavigationItem
                code={item.code}
                hasOnlyOneSubItem={hasOnlyOneSubItem || (level === 2 && navItemsCount === 1)}
                hasSubItems={hasSubItems}
                id={item.id}
                isHamburgerNavigation={isHamburgerNavigation}
                isNavFocused={isNavFocused}
                setIsNavFocused={setIsNavFocused}
                isHighlightLink={item.highlighted}
                key={item.id}
                layoutType={layoutType}
                level={level}
                link={item.link}
                name={item.name}
                onClickItem={onClickItem}
                onClickLevelBack={onClickLevelBack}
                onMouseEnter={handleMouseEnter(item.id)}
                onMouseLeave={handleMouseLeave}
                onRemoveHoverStatusImmediately={onRemoveHoverStatusImmediately}
                renderAllSubLevels={renderAllSubLevels || (index === 0 && level === 1)}
                svg={item.svg}
              />
            );
          }

          return null;
        }),
    [
      navItems,
      layoutType,
      level,
      shouldRenderItem,
      navItemsCount,
      handleCardClick,
      cardSize,
      hasOnlyOneSubItem,
      isHamburgerNavigation,
      isNavFocused,
      setIsNavFocused,
      onClickItem,
      onClickLevelBack,
      handleMouseEnter,
      handleMouseLeave,
      onRemoveHoverStatusImmediately,
      renderAllSubLevels,
    ],
  );

  const renderStoreFinderLink = useCallback(
    () => (
      <li>
        <Link
          as={<HydraLink glyphAfter={location} />}
          className={styles.storefinder}
          data-purpose="navigationList.link.storefinder"
          href={storeFinderLink}
          onClick={onClickItem}
        >
          {storeFinderLinkText}
        </Link>
      </li>
    ),
    [storeFinderLink, onClickItem, storeFinderLinkText],
  );

  useEffect(() => {
    if (isScrollable) {
      setShouldShowScroll(
        (navRef?.current?.scrollWidth ?? 0) > (navRef?.current?.offsetWidth ?? 0),
      );
    }
  }, [id, navRef, renderNavItems, isScrollable]);

  const handleResize = useCallback(() => {
    if (window.innerWidth < config.breakpoints.lg) {
      setShouldShowScroll(false);
      navRef?.current?.scroll({ left: 0 });
      setIsNextActive(true);
    } else {
      setShouldShowScroll(
        (navRef?.current?.scrollWidth ?? 0) > (navRef?.current?.offsetWidth ?? 0),
      );
    }
  }, [setIsNextActive, setShouldShowScroll]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const renderMetaServiceLinks = () => (
    <li className={styles.metaNavLinksWrapper} data-purpose="metaNavigation.links">
      <ul className={styles.metaNavLinksList}>
        {isMountedOnCSR && (
          <li className={styles.metaNavLinksListItem}>
            <MyAccountTeaser onCloseHamburger={onCloseHamburger} />
          </li>
        )}
        {metaNavItems.map((item) => (
          <li className={styles.metaNavLinksListItem} key={item?.link?.linkName}>
            <Link
              as={<HydraLink className={styles.metaNavLink} theme="coal" />}
              data-purpose="metaNavigation.service.link"
              href={item?.link?.url}
              key={item?.link?.url}
              onClick={onClickItem}
              target={item?.link?.target || '_self'}
            >
              {item?.link?.linkName}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );

  const triggerScroll = () => {
    setIsNextActive(!isNextActive);
    navRef?.current?.scroll({
      behavior: 'smooth',
      left: isNextActive ? navRef?.current?.offsetWidth : 0,
    });
  };

  const renderArrow = (glyph: Glyph, direction: string) => (
    <div className={styles.sliderButtonWrapper}>
      <IconButton
        ariaLabel={`scroll ${direction}`}
        className={styles.sliderButton}
        glyph={glyph}
        onClick={triggerScroll}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tabIndex="-1"
      />
    </div>
  );

  return (
    <>
      {!isNextActive && shouldShowScroll && isScrollable && renderArrow(chevronLeft, 'left')}
      <ul className={navigationListClasses} ref={navRef}>
        {parentItem && (
          <>
            <BackButton level={level} onClick={onClickLevelBack} />
            <NavigationItem
              id={parentItem.id}
              isHamburgerNavigation={isHamburgerNavigation}
              isOverview
              isNavFocused={isNavFocused}
              setIsNavFocused={setIsNavFocused}
              key={parentItem.id}
              level={level}
              link={parentItem.link}
              name={parentItem.name}
              onClickItem={onClickItem}
              onRemoveHoverStatusImmediately={onRemoveHoverStatusImmediately}
              svg={parentItem.svg}
            />
          </>
        )}
        {renderNavItems}
        {hasShowAllBtn() && renderShowAllButton}
        {level === 0 && (
          <li className={styles.contrastWrapper}>
            <ContrastToggle className={styles.contrastToggle} />
          </li>
        )}
        {isMetaNavigationEnabled &&
          level === 0 &&
          !isArrayEmpty(metaNavItems) &&
          renderMetaServiceLinks()}
        {level === 0 && !isMetaNavigationEnabled && renderStoreFinderLink()}
      </ul>
      {isNextActive && shouldShowScroll && isScrollable && renderArrow(chevronRight, 'right')}
    </>
  );
});

export default memo(track(tagComponent('NavigationList'))(NavigationList));
